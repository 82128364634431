import React from "react";
import { Card, CardBody, CardTitle, CardReadMore } from "design-react-kit";
import Icons from "@/molecules/Svgs/SvgsList";
import Richtext from "../Richtext";
const mainClassName = "progress-card";

const ProgressCard = ({
  icon,
  header,
  title,
  description,
  cValue,
  tValue,
  percentile,
}) => {
  const Icon = Icons[icon] ? Icons[icon] : null;

  return (
    <Card noWrapper className={`${mainClassName}_container`}>
      <CardTitle className={`${mainClassName}_container_title`} tag="h4">
        {title}
      </CardTitle>
      <CardBody className={`${mainClassName}_container_body`}>
        <div className={`${mainClassName}_container_body_preheader`}>
          {Icon && <Icon role="presentation" />}
          <span>{header}</span>
        </div>
        <div className={`${mainClassName}_container_body_content`}>
          <Richtext
            className={`${mainClassName}_container_body_content_text`}
            textAlign="left"
          >
            {description}
          </Richtext>
          <div className={`${mainClassName}_progress`}>
            <div className={`${mainClassName}_progress_details`}>
              <span className="current">€ {cValue}</span>
              <span>/</span>
              <span className="target">€ {tValue}</span>
            </div>
            <div className={`${mainClassName}_progress_bar`}>
              <div
                className={`${mainClassName}_progress_bar_fill`}
                style={{ width: `${percentile}%` }}
              ></div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProgressCard;
