import { ENDPOINTS } from './config'

export const getRedirectsData = async () => {
  try {
    console.info(`[ 🔄 API: Recuperando redirects dall'API ]`)

    const baseUrl = getApiUrl()
    const redirectsEndpoint = `${baseUrl}redirects`

    // Usa il caching nativo di Next.js (la fetch API è potenziata da Next.js)
    const redirects = await fetchData(redirectsEndpoint, {
      // next: { revalidate: 600 }, // Cache per 10 minuti (600 secondi)
      cache: 'force-cache', // Usa la cache di default di Next.js
    })

    if (!redirects || !Array.isArray(redirects)) {
      throw new Error(`Errore nel recupero dei redirects: dati non validi`)
    }

    console.info(`[ 🔄 API: Recuperati ${redirects.length} redirects ]`)
    return redirects
  } catch (error) {
    console.error('[ ❌ API: Errore durante il recupero dei redirects ]', error)
    return []
  }
}

export const getRedirectIfExists = async (slug) => {
  const redirects = await getRedirectsData()
  const redirect = redirects.find(
    (item) => item.source.replace(/\/$/, '') === slug.replace(/\/$/, ''),
  )
  if (redirect) {
    console.info(
      `[ 🔄 API: Redirect trovato per ${slug} -> ${redirect.destination}]`,
    )
    const permanentRedirectValue =
      redirect.permanent === '1' || redirect.permanent === true ? true : false
    return {
      redirect: {
        destination: redirect.destination,
        permanent: permanentRedirectValue,
      },
    }
  }
  console.info(`[ 🔄 API: Nessun redirect trovato per ${slug}]`)
  return null
}

export const fetchData = async (url, opt = {}) => {
  const isPaginationFetch = url.includes('per_page=')
  let paginationResponses = []

  try {
    const req = await fetch(url, opt)
    console.info('\t[ 🌐 GET:', url, '- STATUS: ', req.status, ' ]')

    if (req.status === 404) return null

    const jsonData = await req.json()

    if (isPaginationFetch && req?.headers) {
      const paginationUrls = getPaginationUrls(url, req)
      if (paginationUrls.length > 0) {
        paginationResponses = await Promise.all(
          paginationUrls.map(async (url) => {
            // Passa le stesse opzioni di caching anche alle chiamate di paginazione
            const res = await fetch(url, opt)
            return res.json()
          }),
        )
      }
      return [...jsonData, ...(paginationResponses.flat() ?? [])]
    }

    return jsonData
  } catch (err) {
    console.error('Fetch error:', err)
    return null
  }
}

export const invalidateRedirectsCache = () => {
  console.info(`[ 🔄 API: Invalidazione cache redirects ]`)
  RedirectsCache.invalidate()
}

export const getApiUrl = (lang = '') => {
  return `${process.env.API_URL}${lang}`
}

/* Use to format internal url using slug */
export const formatUrl = (url) => {
  let domain = process.env.API_URL

  if (process.env.NODE_ENV === 'development') {
    domain = 'http://localhost:3000/'
  }

  return `${domain}${url}`
}

const getPaginationUrls = (url, req) => {
  const pagesRemained = req.headers.get('x-wp-totalpages') ?? 0
  if (pagesRemained === 0) return []

  const numberPages = Array.from(
    { length: pagesRemained },
    (_, i) => i + 1,
  ).splice(1)
  const paginationUrls = numberPages.map((page) => {
    const pageUrl = url.concat(`&page=${page}`)
    return pageUrl
  })
  return paginationUrls
}

export const getPageData = async ({ entity = 'pages', slug = '' }) => {
  const baseUrl = getApiUrl()
  let url = `${baseUrl}${ENDPOINTS[entity]}${
    slug === false ? '' : `?slug=${slug}`
  }`
  const res = await fetchData(url)
  if (Array.isArray(res) && res.length === 1) {
    return res.at(0)
  }
  return res
}

export const getPageById = async (
  { entity = 'pages', id = '', isDraft = false },
  fetchOptions,
) => {
  const baseUrl = getApiUrl()
  if (entity === 'revision') {
    const url = `${baseUrl}${'revision'}/${id}`
    const data = await fetchData(url, fetchOptions)
    console.log({ data })
    return data
  }
  const url = `${baseUrl}${ENDPOINTS[entity]}/?include=${id}${
    isDraft ? `&status=draft` : ''
  }`
  const [data] = await fetchData(url, fetchOptions)
  return data
}

export const getHeaderData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.header
  const apiUrl = `${baseUrl}${endpoint}`
  const data = await fetchData(apiUrl)
  return data
}

export const getFooterData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.footer
  const apiUrl = `${baseUrl}${endpoint}`
  const data = await fetchData(apiUrl)
  return data
}

export const getEntity = async ({ entity }) => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS[entity]
  const perPage = `?per_page=100`
  const apiUrl = `${baseUrl}${endpoint}${perPage}`
  const data = await fetchData(apiUrl)
  if (data && data.length > 0) {
    data[0].entity = entity
  }
  return data
}
