import { PARENT_PAGE_BY_CONTENT_TYPE } from 'api/config'
import { Button as DesignReactKitButton } from 'design-react-kit'
import { useEffect, useState } from 'react'
import { trackCustomEvent, withNextLink } from 'utils/functions'

const VARIANTS = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  outline: 'btn-outline',
  'outline-dark': 'btn-outlinedark',
  link: 'btn-link',
  inverted: 'btn-inverted',
}

const Button = ({
  children,
  className,
  isDisabled = false,
  href: _href = null,
  openInNewTab = false,
  fullWidth = false,
  isInternalPage = false,
  internalUrl: _internalUrl = null,
  variant = 'primary',
  trackEventArgs = null,
  ...props
}) => {
  const [mounted, setMounted] = useState(false)
  const [classNames, setClassNames] = useState(['btn'])

  const externalUrl = isDisabled ? '#!' : _href

  const internalUrlObj = _internalUrl?.[0]

  let internalUrl = null

  if (isInternalPage && internalUrlObj) {
    let { post_name: slug, post_type: type } = internalUrlObj
    if (slug === 'homepage') slug = ''
    if (type === 'page') type = 'pages'

    /* Relative Path */
    internalUrl = `${type !== 'pages' && PARENT_PAGE_BY_CONTENT_TYPE[type]
      ? PARENT_PAGE_BY_CONTENT_TYPE[type]
      : ''
      }/${slug}`
  }

  const href = isInternalPage ? internalUrl : externalUrl

  const additionalClassNames = `
   ${isDisabled ? 'disabled' : ''}
   ${fullWidth ? 'w-100' : ''}
   ${VARIANTS[variant]}
  `

  const onClickCapture = () => {
    if (trackEventArgs) {
      if (trackEventArgs?.value) {
        trackEventArgs.value = ` ${trackEventArgs.value}  ${href ? `url-> ${href}` : ''
          }`
      }
      trackCustomEvent(trackEventArgs)
    }
  }

  useEffect(() => {
    setClassNames([additionalClassNames, className])
    setMounted(true)
  }, [])

  if (!mounted || !children) return <></>

  return !openInNewTab ? (
    withNextLink(
      <DesignReactKitButton
        className={classNames}
        href={href}
        target={openInNewTab ? '_blank' : null}
        aria-label={`Vai in un altra pagina`}
        {...props}
        onClickCapture={onClickCapture}
      >
        {children}
      </DesignReactKitButton>,
      href,
    )
  ) : (
    <DesignReactKitButton
      className={classNames}
      href={href}
      target={openInNewTab ? '_blank' : null}
      aria-label={`Vai in un altra pagina`}
      {...props}
      onClickCapture={onClickCapture}
    >
      {children}
    </DesignReactKitButton>
  )
}

export default Button
