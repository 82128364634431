const ChevronRight = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    role="presentation"
  >
    <g id='Navigation / Chevron / Right'>
      <path
        id='Path'
        d='M6.39954 11.7333L5.86621 11.2666L9.13288 7.99994L5.86621 4.73327L6.39954 4.2666L10.1329 7.99994L6.39954 11.7333Z'
        fill='white'
      />
    </g>
  </svg>
)

const ChevronLeft = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    role="presentation"
  >
    <g id='Navigation / Chevron / Left'>
      <path
        id='Path'
        d='M9.60046 11.7333L10.1338 11.2666L6.86712 7.99994L10.1338 4.73327L9.60046 4.2666L5.86712 7.99994L9.60046 11.7333Z'
        fill='white'
      />
    </g>
  </svg>
)

const ChevronUp = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    role="presentation"
  >
    <path
      d='M16.9004 15.1998L12.0004 10.2998L7.10039 15.1998L6.40039 14.3998L12.0004 8.7998L17.6004 14.3998L16.9004 15.1998Z'
      fill='currentColor'
    />
  </svg>
)

const ChevronDown = ({ className, isOpen = null }) => (
  <svg
    aria-expanded={isOpen}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='8'
    viewBox='0 0 12 8'
    fill='currentColor'
  >
    <path
      d='M6.00039 7.19981L0.400391 1.5998L1.10039 0.799805L6.00039 5.69981L10.9004 0.799805L11.6004 1.5998L6.00039 7.19981Z'
      fill='currentColor'
    />
  </svg>
)

export { ChevronDown, ChevronLeft, ChevronRight, ChevronUp }
