import parse, { domToReact } from 'html-react-parser'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const textAlignProps = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
}

const Richtext = ({
  unparsedHtml = null,
  children = null,
  className = '',
  textAlign = 'center',
  fullWidth = true,
  isDefault = true,
  hasFlagTag = false,
  textStyle = {},
  role = 'paragraph',
  as = 'div',
}) => {
  const [mounted, setMounted] = useState(false)
  const dataToParse = unparsedHtml ? unparsedHtml : children
  const defaultClassName = 'richtext-default'
  const classesToInject = `
    ${isDefault ? defaultClassName : ''}
    ${textAlignProps[textAlign]}
    ${className}
    ${fullWidth ? 'w-100' : ''}
  `

  useEffect(() => setMounted(true), [])

  if (!mounted || !children) return <></>

  const TagName = as

  return (
    <TagName
      className={classesToInject}
      style={textStyle}
      role={role}
    >
      {hasAnchorLinkFlagTag(dataToParse) && hasFlagTag
        ? parseAndReplaceWithAnchorLink(dataToParse)
        : parse(dataToParse, {
            replace: enhanceAccessibility,
          })}
    </TagName>
  )
}

const enhanceAccessibility = (domNode) => {
  if (!domNode.attribs) return

  if (domNode.name === 'img' && !domNode.attribs.alt) {
    domNode.attribs.alt = ''
  }

  if (
    domNode.name === 'em' ||
    domNode.name === 'strong' ||
    domNode.name === 'p' ||
    domNode.name === 'b' ||
    domNode.name === 'i' ||
    domNode.name === 'span' ||
    domNode.name === 'div'
  ) {
    domNode.attribs.role = 'paragraph'
  }

  if (domNode.name === 'ul' || domNode.name === 'ol') {
    domNode.attribs.role = 'menu'
  }

  if (domNode.name === 'li') {
    domNode.attribs.role = 'listitem'
  }

  if (domNode.name === 'a') {
    domNode.attribs.role = 'link'
    domNode.attribs.tabIndex = 0
  }
  if (domNode.name === 'button') {
    domNode.attribs.role = 'button'
    domNode.attribs.tabIndex = 0
  }
}

// if a word has <span style={{ color: '#ff0000' }}>...</span>
// then we need to add an anchor link to it
const hasAnchorLinkFlagTag = (text) => {
  if (text && typeof text === 'string') {
    if (text.includes('<span style="color: #ff0000;">')) return true
    if (text.includes('<span style="color: #ff0000">')) return true
  }
  return false
}

const parseAndReplaceWithAnchorLink = (text) => {
  const options = {
    replace: ({ attribs, children, ...domNode }) => {
      if (!attribs) return
      const { style } = attribs
      if (!style) return
      if (style === 'color: #ff0000;' || style === 'color: #ff0000') {
        const getHrefFromChildren = (children) => {
          let result = ''
          if (!children) return result
          if (children.length === 1) {
            result = children.at(0)?.data || ''
          }
          const concatHref = children.map((child) => {
            if (child.type === 'text') {
              return child.data
            }
            if (child.children) {
              return getHrefFromChildren(child.children)
            }
            return ''
          })
          result = concatHref.join('')
          return result.toLowerCase().trim().replaceAll(' ', '-')
        }

        const linkText = domToReact(children, options)
        const href = `#${getHrefFromChildren(children)}`

        // Miglioramento dei link per accessibilità
        return (
          <Link
            href={href}
            aria-label={`Vai alla sezione ${linkText}`}
            className='anchor-link'
          >
            {linkText}
          </Link>
        )
      }
    },
  }

  return parse(text, options)
}

export default Richtext
