import { Icon } from 'design-react-kit'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'

const defaultIconColor = 'hsl(210,17%,44%)'
const inputId = `search-input-${uuid()}`

const Input = ({
  type = 'text',
  showLabel = false,
  label = 'Input',
  placeholder = 'Inserisci la parola da cercare',
  iconColor = defaultIconColor,
  className = '',
  onChange = () => {},
  value: _value = '',
  ...props
}) => {
  const [value, setValue] = useState(_value)
  const [isFocused, setIsFocused] = useState(false)
  const inputClassName = `${
    isFocused
      ? `form-control focus--mouse ${className}`
      : `form-control ${className}`
  } `

  const toggleFocusLabel = () => setIsFocused(true)
  const toggleBlurLabel = (e) =>
    e.target.value === '' ? setIsFocused(!isFocused) : null

  const handleOnChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <div className='form-group'>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <div className='input-group-text'>
            <Icon
              icon='it-search'
              size={'sm'}
              role='presentation'
              aria-hidden='true'
              style={{
                fill: iconColor,
              }}
            />
          </div>
        </div>
        {showLabel ? (
          <label
            htmlFor={inputId}
            className={`${isFocused ? 'active' : ''}`}
          >
            {label}
          </label>
        ) : null}
        <input
          {...props}
          id={inputId}
          type={type}
          className={inputClassName}
          onFocus={toggleFocusLabel}
          onBlur={toggleBlurLabel}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          aria-label={label}
        />
        {/*
           <div className='input-group-append'>
                IF YOU NEED TO ADD A BUTTON TO THE INPUT
          </div>
        */}
      </div>
    </div>
  )
}

export default Input
