const ArrowRight = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    role="presentation"
  >
    <path
      d='M13.9 5L13.2 5.7L18.5 11.1H3V12.1H18.5L13.2 17.5L13.9 18.2L20.5 11.6L13.9 5Z'
      fill='#4D9997'
    />
  </svg>
)

const ArrowLeft = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    role="presentation"
  >
    <path
      d='M10.1 5L10.8 5.7L5.5 11.1H21V12.1H5.5L10.8 17.5L10.1 18.2L3.5 11.6L10.1 5Z'
      fill='#4D9997'
    />
  </svg>
)

export { ArrowLeft, ArrowRight }
