import { Headers } from 'design-react-kit'
import { useIntersectionObserver, useMediaQuery } from 'hooks'
import { useRouter } from 'next/router'
import CenterHeader from './CenterHeader'
import NavHeader from './NavHeader'
import SlimHeader from './SlimHeader'

const logoSrcDesktop = '/logo-dl.png'
const logoSrcMobile = '/logo-dl.png'

const headerDefault = {
  slimHeader: {
    owner: 'Ministero della Cultura',
  },
  centerHeader: {
    title: 'Istituto centrale per la digitalizzazione del patrimonio culturale',
    subtitle: '',
    socialItems: [],
  },
}

const CompleteHeader = ({ header }) => {
  const router = useRouter()
  const { homepage, navItems, socialItems, searchItem } = header
  const isDesktop = useMediaQuery('lg')
  const logoByViewport = isDesktop ? logoSrcDesktop : logoSrcMobile
  // const logoByViewport = logoSrcMobile
  const { ref: headerWrapperRef, isIntersecting: isHeaderDesktopVisible } =
    useIntersectionObserver({
      freezeOnceVisible: false,
      rootMargin: '-50px',
      initialIsIntersecting: true,
      threshold: 0.5,
    })

  const { ref: slimHeaderRef, isIntersecting: isSlimHeaderVisible } =
    useIntersectionObserver({
      freezeOnceVisible: false,
    })

  const handleOwnerClick = (router) => () =>
    router.push('https://www.beniculturali.it/')
  const handleSearchIconClick = (router) => () =>
    router.replace(searchItem?.url || '/')

  return (
    <Headers
      id='header'
      role='heading'
    >
      <div
        className={`
      ${
        !isSlimHeaderVisible && !isDesktop
          ? 'slim-wrap it-header-sticky'
          : 'slim-wrap it-header-normal'
      }
      `}
        ref={slimHeaderRef}
      >
        <SlimHeader
          owner={headerDefault.slimHeader.owner}
          onOwnerClick={handleOwnerClick(router)}
        />
      </div>
      <div
        ref={headerWrapperRef}
        className={` it-nav-wrapper ${
          isDesktop
            ? 'it-header-normal'
            : !isHeaderDesktopVisible
            ? 'it-header-sticky'
            : 'it-header-normal'
        }`}
      >
        <CenterHeader
          {...headerDefault.centerHeader}
          socialItems={socialItems}
          searchItem={searchItem}
          logo={logoByViewport}
          onSearchClick={handleSearchIconClick(router)}
        />
        <NavHeader
          navItems={navItems}
          homepage={homepage}
          currentPath={router.asPath}
          isHeaderVisible={isDesktop ? isHeaderDesktopVisible : true}
        />
      </div>
    </Headers>
  )
}

export default CompleteHeader
