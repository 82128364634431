import { Breadcrumbs, Richtext } from '@/atoms'
import { useH1Status } from '@/organisms/Layout/RootLayout'
import { Hero, HeroBody } from 'design-react-kit'
import Image from 'next/image'
import { getReadableDate } from 'utils/functions'
import { PixelDecorator } from '..'

const mainClassName = 'full-overlay-hero'
const publishedAtLabel = 'Pubblicato il: \xa0'

const FullOverlayHero = ({ items, isFirstSection, isHero }) => {
  const hasH1InPage = useH1Status()
  const headingTag = hasH1InPage && !isFirstSection ? 'h2' : 'h1'
  const {
    title,
    description,
    image,
    published_at: publishedAt,
    pixelDecorator,
    customInset = null,
  } = items

  const pixelDecoratorInsetOffset = {
    A: '0,0,0,0',
    B: '0,0,0,0',
    C: '0,0,0,0',
  }

  return (
    <section className={`${mainClassName + '_section'}`}>
      <Hero overlay='dark'>
        <div className='img-responsive-wrapper'>
          <div className='img-responsive'>
            <div className='img-wrapper'>
              <PixelDecorator
                pixelDecorator={pixelDecorator}
                insetOffset={pixelDecoratorInsetOffset}
                inset={customInset}
                hideOnMobile={true}
              >
                <Image
                  src={image.url ?? image}
                  fill
                  alt={image.alt ?? 'Immagine sfondo hero'}
                  className={`${mainClassName}_section_bg-img`}
                />
              </PixelDecorator>
            </div>
          </div>
        </div>
        <HeroBody className={`${mainClassName + '_section_text-wrapper'}`}>
          {isHero ? (
            <Breadcrumbs
              className={`${
                mainClassName + '_section_text-wrapper_breadcrumbs'
              }`}
            />
          ) : null}
          {headingTag === 'h1' ? (
            <h1 className={`${mainClassName + '_section_text-wrapper_title'}`}>
              {title}
            </h1>
          ) : (
            <h2 className={`${mainClassName + '_section_text-wrapper_title'}`}>
              {title}
            </h2>
          )}
          <Richtext
            text={description}
            textAlign={'left'}
            className={`${mainClassName + '_section_text-wrapper_description'}`}
          >
            {description}
          </Richtext>
          <div
            className={`${
              mainClassName + '_section_text-wrapper_published-at'
            }`}
          >
            <p role={'paragraph'}>
              {publishedAtLabel}
              <b
                role={'paragraph'}
                style={{ fontSize: '1.125rem' }}
              >
                {getReadableDate(publishedAt)}
              </b>
            </p>
          </div>
        </HeroBody>
      </Hero>
    </section>
  )
}

export default FullOverlayHero
