import { useState, useEffect } from "react";
import { useMediaQuery } from "hooks";

import Richtext from "@/atoms/Richtext";
import InvestmentCard from "@/atoms/Cards/InvestmentCard";

import Icons from "@/molecules/Svgs/SvgsList";

const mainClassName = "highlighted-card-list";

const HighlightedCardList = ({ data, darkBackground }) => {
  const { title, description, section_title: sectionTitle, items, icon } = data;
  const [showAll, setShowAll] = useState(false);
  const [cards, setCards] = useState(items);
  const isDesktop = useMediaQuery("sm");

  const Icon = Icons[icon] ? Icons[icon] : null;

  useEffect(() => {
    if (isDesktop) {
      setCards(items);
      setShowAll(false);
      return;
    }

    if (!showAll) {
      setCards(items.slice(0, 3));
    } else {
      setCards(items);
    }
  }, [showAll, isDesktop]);

  return (
    <section
      className={`${mainClassName + "_section"} ${
        darkBackground ? "darkBg" : ""
      }`}
    >
      <div className={`${mainClassName + "_section_container"}`}>
        <div className={`${mainClassName + "_section_container_paragraph"}`}>
          <h2
            className={`${
              mainClassName + "_section_container_paragraph_title"
            }`}
          >
            {title}
          </h2>
        </div>
        <div className={`${mainClassName + "_section_container_cards"}`}>
          <div
            className={`${mainClassName + "_section_container_cards_header"}`}
          >
            <div
              className={`${
                mainClassName + "_section_container_cards_header_title"
              }`}
            >
              {Icon && <Icon role="presentation" />}
              <h3>{sectionTitle}</h3>
            </div>
            <Richtext
              isDefault={false}
              className={`${
                mainClassName + "_section_container_cards_header_abstract"
              }`}
              textAlign={"left"}
            >
              {description}
            </Richtext>
          </div>
          <div className={`${mainClassName + "_section_container_cards_main"}`}>
            {items &&
              !!items.length &&
              cards.map((item, index) => (
                <InvestmentCard key={index} {...item} />
              ))}
          </div>
          {!isDesktop && (
            <div
              className={`
              ${mainClassName + "_section_container_cards_show"}
              ${showAll ? "opened" : ""}
            `}
            >
              <button onClick={() => setShowAll(!showAll)}>
                {showAll ? "Mostra meno" : "Mostra tutto"}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HighlightedCardList;
