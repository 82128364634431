const Computer = ({ className, style }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    role="presentation"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 4C1.5 2.61929 2.61929 1.5 4 1.5H20C21.3807 1.5 22.5 2.61929 22.5 4V14C22.5 15.3807 21.3807 16.5 20 16.5H4C2.61929 16.5 1.5 15.3807 1.5 14V4ZM4 2.5C3.17157 2.5 2.5 3.17157 2.5 4V14C2.5 14.8284 3.17157 15.5 4 15.5H20C20.8284 15.5 21.5 14.8284 21.5 14V4C21.5 3.17157 20.8284 2.5 20 2.5H4Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 22C6.5 21.7239 6.72386 21.5 7 21.5H17C17.2761 21.5 17.5 21.7239 17.5 22C17.5 22.2761 17.2761 22.5 17 22.5H7C6.72386 22.5 6.5 22.2761 6.5 22Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 15.5C12.2761 15.5 12.5 15.7239 12.5 16V22C12.5 22.2761 12.2761 22.5 12 22.5C11.7239 22.5 11.5 22.2761 11.5 22V16C11.5 15.7239 11.7239 15.5 12 15.5Z"
      fill="white"
    />
  </svg>
);

export default Computer;
