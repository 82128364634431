const ThreeRectangles = ({ className, style }) => (
  <svg
    className={className}
    width='231'
    height='93'
    viewBox='0 0 231 93'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
    role="presentation"
  >
    <defs>
      <linearGradient
        id='paint0_linear_1271_71684'
        x1='138.098'
        y1='77.9189'
        x2='1.49837e-06'
        y2='77.9189'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#53c6c2' />
        <stop
          offset='0.708333'
          stopColor='#53c6c2'
          stopOpacity='0'
        />
      </linearGradient>
    </defs>
    <g id='sign-1'>
      <path
        d='M75.3261 0H105.457V30.1622H75.3261V0Z'
        fill='#FCF7E6'
      />
      <path
        d='M138.098 62.8378H168.228V93H138.098V62.8378Z'
        fill='#FCF7E6'
      />
      <path
        d='M200.87 30.1622H231V60.3243H200.87V30.1622Z'
        fill='#53c6c2'
      />
      <path
        d='M0 62.8378H138.098V93H0V62.8378Z'
        fill='url(#paint0_linear_1271_71684)'
      />
    </g>
  </svg>
)

export default ThreeRectangles
