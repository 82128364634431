const Children = ({ className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
    role="presentation"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 2.5C4.51472 2.5 2.5 4.51472 2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C9.48528 11.5 11.5 9.48528 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.11488 3.51338C6.28353 3.55319 6.41954 3.67749 6.47434 3.84189C6.79203 4.79496 7.66027 5.30904 8.77438 5.53498C9.88333 5.75987 11.1135 5.66739 11.9019 5.50971C12.1727 5.45555 12.4361 5.63116 12.4903 5.90194C12.5444 6.17272 12.3688 6.43613 12.0981 6.49029C11.2198 6.66594 9.85 6.77347 8.57562 6.51503C7.51686 6.30031 6.43779 5.8106 5.82906 4.81421C5.65477 4.93706 5.44392 5.05503 5.17556 5.15566C4.59415 5.37369 3.77521 5.5 2.5 5.5C2.22386 5.5 2 5.27614 2 5C2 4.72386 2.22386 4.5 2.5 4.5C3.72479 4.5 4.40585 4.37631 4.82444 4.21934C5.21955 4.07117 5.40682 3.88607 5.64645 3.64645C5.76898 3.52391 5.94622 3.47356 6.11488 3.51338Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.69969 8.60023C4.92048 8.43438 5.23392 8.47891 5.39977 8.69969C5.76554 9.18661 6.34609 9.5 7 9.5C7.65391 9.5 8.23446 9.18661 8.60023 8.69969C8.76608 8.47891 9.07952 8.43438 9.30031 8.60023C9.52109 8.76608 9.56562 9.07952 9.39977 9.30031C8.85333 10.0277 7.98183 10.5 7 10.5C6.01817 10.5 5.14667 10.0277 4.60023 9.30031C4.43438 9.07952 4.47891 8.76608 4.69969 8.60023Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6997 15.6002C13.9205 15.4344 14.2339 15.4789 14.3998 15.6997C14.7655 16.1866 15.3461 16.5 16 16.5C16.6539 16.5 17.2345 16.1866 17.6002 15.6997C17.7661 15.4789 18.0795 15.4344 18.3003 15.6002C18.5211 15.7661 18.5656 16.0795 18.3998 16.3003C17.8533 17.0277 16.9818 17.5 16 17.5C15.0182 17.5 14.1467 17.0277 13.6002 16.3003C13.4344 16.0795 13.4789 15.7661 13.6997 15.6002Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 9.5C13.5147 9.5 11.5 11.5147 11.5 14C11.5 16.4853 13.5147 18.5 16 18.5C18.4853 18.5 20.5 16.4853 20.5 14C20.5 11.5147 18.4853 9.5 16 9.5ZM10.5 14C10.5 10.9624 12.9624 8.5 16 8.5C19.0376 8.5 21.5 10.9624 21.5 14C21.5 17.0376 19.0376 19.5 16 19.5C12.9624 19.5 10.5 17.0376 10.5 14Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8.5C16.2761 8.5 16.5 8.72386 16.5 9C16.5 10.1847 17.2038 11.1813 18.1883 11.9728C19.1693 12.7616 20.3579 13.2854 21.1437 13.5211C21.4082 13.6004 21.5583 13.8792 21.4789 14.1437C21.3996 14.4082 21.1208 14.5583 20.8563 14.4789C19.9754 14.2146 18.664 13.6384 17.5617 12.7522C16.4629 11.8687 15.5 10.6153 15.5 9C15.5 8.72386 15.7239 8.5 16 8.5Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8.5C15.7239 8.5 15.5 8.72386 15.5 9C15.5 10.1847 14.7962 11.1813 13.8117 11.9728C12.8307 12.7616 11.642 13.2854 10.8563 13.5211C10.5918 13.6004 10.4417 13.8792 10.5211 14.1437C10.6004 14.4082 10.8792 14.5583 11.1437 14.4789C12.0246 14.2146 13.336 13.6384 14.4383 12.7522C15.5371 11.8687 16.5 10.6153 16.5 9C16.5 8.72386 16.2761 8.5 16 8.5Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6934 16.6387L11.2774 16.916C10.685 17.311 10.2298 18.1584 10.0549 19.1494C9.90103 20.0213 9.98556 20.8857 10.2843 21.4929C10.8681 21.4622 11.3845 21.327 11.8126 20.9846C12.3074 20.5887 12.7685 19.8475 13.0068 18.4178C13.0522 18.1454 13.3098 17.9614 13.5822 18.0068C13.8545 18.0522 14.0386 18.3098 13.9932 18.5822C13.7314 20.1525 13.1925 21.1613 12.4373 21.7654C11.6837 22.3683 10.7987 22.5 9.99996 22.5C9.83278 22.5 9.67666 22.4164 9.58393 22.2773C8.98746 21.3826 8.8696 20.1118 9.07011 18.9756C9.27024 17.8416 9.81504 16.689 10.7227 16.084L11.1387 15.8066L11.6934 16.6387Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.2863 16.6387L20.7024 16.916C21.2948 17.311 21.75 18.1584 21.9248 19.1494C22.0787 20.0213 21.9942 20.8857 21.6954 21.4929C21.1116 21.4622 20.5952 21.327 20.1671 20.9846C19.6723 20.5887 19.2112 19.8475 18.973 18.4178C18.9276 18.1454 18.67 17.9614 18.3976 18.0068C18.1252 18.0522 17.9412 18.3098 17.9866 18.5822C18.2483 20.1525 18.7872 21.1613 19.5424 21.7654C20.2961 22.3683 21.181 22.5 21.9798 22.5C22.1469 22.5 22.3031 22.4164 22.3958 22.2773C22.9923 21.3826 23.1101 20.1118 22.9096 18.9756C22.7095 17.8416 22.1647 16.689 21.2571 16.084L20.841 15.8066L20.2863 16.6387Z"
      fill="#53c6c2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 16C0.5 13.5147 2.51472 11.5 5 11.5H9C9.85473 11.5 10.6552 11.7388 11.3367 12.1535L10.8167 13.0078C10.2875 12.6856 9.6662 12.5 9 12.5H5C3.067 12.5 1.5 14.067 1.5 16V23C1.5 23.2761 1.27614 23.5 1 23.5C0.723858 23.5 0.5 23.2761 0.5 23V16ZM13 20C13.2761 20 13.5 20.2239 13.5 20.5V23C13.5 23.2761 13.2761 23.5 13 23.5C12.7239 23.5 12.5 23.2761 12.5 23V20.5C12.5 20.2239 12.7239 20 13 20Z"
      fill="#53c6c2"
    />
  </svg>
);

export default Children;
