import { Nav, NavItem, NavLink, TabContent, TabPane } from 'design-react-kit'

import { useState } from 'react'
import { TextCollapser } from '.'
import Richtext from './Richtext'

const TabsComponent = ({ items, classNameToPass = 'tabs' }) => {
  const [activeTab, setActiveTab] = useState('1')
  return (
    <section className={`${classNameToPass + '_section'}`}>
      <TabHeader
        items={items}
        activeTab={activeTab}
        toggleTab={setActiveTab}
        classNameToPass={`${classNameToPass + '_section_header'}`}
      />
      <TabBody
        items={items}
        activeTab={activeTab}
        classNameToPass={`${classNameToPass + '_section_body'}`}
      />
    </section>
  )
}

const TabItem = ({
  children,
  href = '#',
  isActive = false,
  onClick = () => {},
}) => {
  return (
    <NavItem>
      <NavLink
        href={href}
        active={isActive}
        onClick={onClick}
        role={'button'}
      >
        {children}
      </NavLink>
    </NavItem>
  )
}

const TabHeader = ({ items, classNameToPass, activeTab, toggleTab }) => {
  const hasItems = items && items.length > 0 ? true : false
  if (!hasItems || items.length === 1) return null
  return (
    <Nav
      tabs
      className={`${classNameToPass}`}
    >
      {hasItems
        ? items.map((item, index) => {
            const componentKey = `tab-link-${index}`
            const isActive = activeTab === `${index + 1}`
            const onClick = () =>
              activeTab !== `${index + 1}` && toggleTab(`${index + 1}`)
            const { title } = item
            return (
              <TabItem
                key={componentKey}
                isActive={isActive}
                onClick={onClick}
              >
                {title}
              </TabItem>
            )
          })
        : null}
    </Nav>
  )
}

const TabBody = ({ activeTab, items, classNameToPass }) => {
  const hasItems = items && items.length > 0 ? true : false
  return (
    <TabContent
      activeTab={activeTab}
      className={classNameToPass}
    >
      {hasItems
        ? items?.map((item, index) => {
            const componentKey = `tab-content-${index}`
            const tabId = `${index + 1}`
            const { description } = item

            const items = item.items || []

            const half = Math.ceil(items.length / 2)
            const firstColumn = items.slice(0, half)
            const secondColumn = items.slice(half)

            return (
              <TabPane
                tabId={tabId}
                key={componentKey}
              >
                {description ? (
                  <div className={`${classNameToPass + '_abstract'}`}>
                    <Richtext
                      className={`${classNameToPass + '_abstract_text'}`}
                      textAlign={'left'}
                    >
                      {description}
                    </Richtext>
                  </div>
                ) : null}

                <div className={`${classNameToPass + '_content'}`}>
                  <BodyColumn
                    items={firstColumn}
                    classNameToPass={classNameToPass}
                  />

                  <BodyColumn
                    items={secondColumn}
                    classNameToPass={classNameToPass}
                  />
                </div>
              </TabPane>
            )
          })
        : null}
    </TabContent>
  )
}

const BodyColumn = ({ items, classNameToPass }) => {
  return (
    <div className={`${classNameToPass + '_content_column'}`}>
      {items.map((item, index) => {
        const { title, text, definitionAnchor = null } = item

        const normalizeAnchorId = (anchorId) => {
          let normalizedAnchorId = anchorId
            .trim()
            .toLowerCase()
            .replaceAll(' ', '-')
          if (!anchorId) return ''
          return normalizedAnchorId
        }

        const anchorId = definitionAnchor
          ? { id: normalizeAnchorId(definitionAnchor) }
          : {}

        return (
          <div
            className={`${classNameToPass + '_content_item'}`}
            key={`tab-content-${index}`}
          >
            <h3
              className={`${classNameToPass + '_content_item_title'}`}
              {...anchorId}
            >
              {title}
            </h3>
            <TextCollapser
              className={`${classNameToPass + '_content_item_text'}`}
            >
              {text}
            </TextCollapser>
          </div>
        )
      })}
    </div>
  )
}

export default TabsComponent
