import {
  Button,
  Chip as ChipDesignReactKit,
  ChipLabel,
  Icon,
} from 'design-react-kit'

const Chip = ({
  label: _label = 'Chip',
  onDelete,
  isLarge = true,
  isCapitalized = true,
  ...props
}) => {
  const label = isCapitalized
    ? _label.at(0).toUpperCase() + _label.slice(1)
    : _label
  return (
    <ChipDesignReactKit large={isLarge}>
      <ChipLabel>{label}</ChipLabel>
      <Button onClick={onDelete}>
        <Icon icon='it-close-big' alt={label} />
      </Button>
    </ChipDesignReactKit>
  )
}

export default Chip
