const MiCFooterLogo = ({ className }) => (
  <svg
    className={className}
    version='1.1'
    id='Logo'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 308.62 100'
    space='preserve'
	role="presentation"
  >
    <g>
      <g>
        <path
          fill='#FFFFFF'
          d='M123.51,19.21c0.14-0.36,0.28-0.71,0.42-1.06c0.14-0.34,0.3-0.68,0.48-1l8.9-16.2
			c0.16-0.31,0.34-0.5,0.53-0.56c0.19-0.06,0.46-0.09,0.8-0.09h2.86v26.84h-3.39V7.84c0-0.27,0.01-0.57,0.02-0.88
			c0.01-0.31,0.03-0.63,0.06-0.95l-9.02,16.5c-0.14,0.29-0.33,0.51-0.57,0.65c-0.24,0.15-0.51,0.22-0.81,0.22h-0.54
			c-0.64,0-1.1-0.29-1.4-0.88l-9.2-16.57c0.02,0.34,0.05,0.67,0.07,0.99c0.03,0.32,0.04,0.63,0.04,0.92v19.31h-3.39V0.3h2.86
			c0.34,0,0.6,0.03,0.79,0.09c0.19,0.06,0.37,0.25,0.53,0.56l9.07,16.24C122.94,17.82,123.24,18.49,123.51,19.21L123.51,19.21z'
        />
        <polygon
          fill='#FFFFFF'
          points='148.26,27.14 144.39,27.14 144.39,0.3 148.26,0.3 148.26,27.14 		'
        />
        <path
          fill='#FFFFFF'
          d='M157.92,0.41c0.17,0.07,0.36,0.26,0.57,0.54l15.28,19.81c-0.02-0.34-0.05-0.67-0.07-1.01
			c-0.02-0.34-0.03-0.65-0.03-0.94V0.3h3.39v26.84h-1.95c-0.3,0-0.55-0.05-0.76-0.16c-0.21-0.11-0.41-0.28-0.61-0.53l-15.3-19.81
			c0.02,0.32,0.05,0.65,0.07,0.96c0.02,0.32,0.03,0.61,0.03,0.89v18.65h-3.39V0.3h2C157.5,0.3,157.75,0.34,157.92,0.41L157.92,0.41z
			'
        />
        <polygon
          fill='#FFFFFF'
          points='187.84,27.14 183.97,27.14 183.97,0.3 187.84,0.3 187.84,27.14 		'
        />
        <path
          fill='#FFFFFF'
          d='M208.57,4.62c-0.11,0.2-0.23,0.35-0.36,0.44c-0.13,0.09-0.3,0.14-0.51,0.14c-0.22,0-0.48-0.1-0.77-0.31
			c-0.29-0.21-0.65-0.44-1.08-0.69c-0.43-0.26-0.94-0.49-1.54-0.69c-0.6-0.21-1.32-0.31-2.15-0.31c-0.8,0-1.5,0.1-2.1,0.31
			c-0.61,0.21-1.11,0.49-1.52,0.85c-0.4,0.36-0.71,0.79-0.92,1.28c-0.21,0.49-0.31,1.03-0.31,1.6c0,0.74,0.18,1.34,0.55,1.81
			c0.37,0.47,0.85,0.88,1.45,1.23c0.6,0.34,1.28,0.64,2.05,0.89c0.77,0.25,1.55,0.51,2.35,0.79c0.8,0.27,1.58,0.58,2.35,0.93
			c0.77,0.34,1.45,0.78,2.05,1.31c0.6,0.53,1.08,1.18,1.45,1.95c0.37,0.77,0.55,1.71,0.55,2.82c0,1.18-0.2,2.29-0.61,3.32
			c-0.41,1.03-0.99,1.93-1.77,2.69c-0.77,0.77-1.72,1.37-2.84,1.81c-1.12,0.44-2.4,0.66-3.83,0.66c-0.86,0-1.69-0.08-2.5-0.25
			c-0.8-0.17-1.56-0.41-2.28-0.71c-0.72-0.31-1.39-0.67-2.02-1.1c-0.63-0.43-1.2-0.91-1.69-1.45l1.12-1.83
			c0.1-0.15,0.23-0.27,0.38-0.36c0.16-0.09,0.33-0.14,0.51-0.14c0.26,0,0.57,0.14,0.91,0.41c0.34,0.27,0.77,0.58,1.27,0.91
			c0.5,0.33,1.11,0.63,1.82,0.91c0.71,0.27,1.57,0.41,2.58,0.41c0.84,0,1.58-0.11,2.24-0.34c0.66-0.22,1.22-0.54,1.68-0.95
			c0.45-0.41,0.8-0.91,1.04-1.5c0.24-0.59,0.36-1.23,0.36-1.95c0-0.8-0.18-1.45-0.55-1.95c-0.37-0.5-0.85-0.93-1.45-1.26
			c-0.6-0.34-1.28-0.62-2.04-0.86c-0.76-0.24-1.54-0.48-2.35-0.74c-0.8-0.26-1.59-0.55-2.35-0.89c-0.76-0.34-1.44-0.78-2.04-1.32
			c-0.6-0.54-1.08-1.22-1.45-2.03c-0.37-0.81-0.55-1.81-0.55-3.01c0-0.95,0.19-1.87,0.56-2.77c0.38-0.9,0.92-1.69,1.63-2.38
			c0.71-0.69,1.59-1.24,2.63-1.66C199.56,0.21,200.75,0,202.1,0c1.51,0,2.89,0.24,4.14,0.71c1.25,0.47,2.35,1.16,3.28,2.06
			L208.57,4.62L208.57,4.62z'
        />
        <polygon
          fill='#FFFFFF'
          points='233.52,0.3 233.52,3.54 224.94,3.54 224.94,27.14 221.08,27.14 221.08,3.54 212.48,3.54 212.48,0.3
			233.52,0.3 		'
        />
        <polygon
          fill='#FFFFFF'
          points='254.21,0.3 254.21,3.44 241.47,3.44 241.47,12.08 251.74,12.08 251.74,15.12 241.47,15.12 241.47,24
			254.23,24 254.21,27.14 237.6,27.14 237.6,0.3 254.21,0.3 		'
        />
        <path
          fill='#FFFFFF'
          d='M263.23,13.24h3.7c1.04,0,1.95-0.12,2.73-0.37c0.79-0.25,1.44-0.6,1.96-1.07c0.52-0.46,0.92-1.01,1.19-1.64
			c0.27-0.63,0.4-1.33,0.4-2.1c0-1.56-0.51-2.74-1.53-3.54c-1.02-0.8-2.56-1.2-4.62-1.2h-3.84V13.24L263.23,13.24z M263.23,16.07
			v11.07h-3.85V0.3h7.69c1.72,0,3.2,0.17,4.45,0.52c1.25,0.35,2.28,0.85,3.1,1.51c0.82,0.65,1.42,1.44,1.81,2.37
			c0.39,0.92,0.58,1.95,0.58,3.09c0,0.95-0.15,1.83-0.44,2.66c-0.29,0.82-0.72,1.56-1.27,2.22c-0.55,0.65-1.23,1.22-2.04,1.69
			c-0.8,0.47-1.72,0.83-2.74,1.07c0.49,0.29,0.9,0.69,1.25,1.22l7.65,10.51h-3.44c-0.7,0-1.21-0.27-1.53-0.82l-6.73-9.41
			c-0.21-0.3-0.44-0.51-0.69-0.65c-0.25-0.13-0.62-0.2-1.12-0.2H263.23L263.23,16.07z'
        />
        <path
          fill='#FFFFFF'
          d='M303.79,13.71c0-1.61-0.22-3.06-0.65-4.34c-0.44-1.28-1.06-2.37-1.88-3.26c-0.82-0.89-1.8-1.57-2.95-2.05
			c-1.15-0.47-2.43-0.71-3.85-0.71c-1.42,0-2.71,0.24-3.86,0.71c-1.15,0.47-2.14,1.16-2.95,2.05c-0.82,0.89-1.45,1.98-1.89,3.26
			c-0.44,1.28-0.66,2.73-0.66,4.34c0,1.62,0.22,3.07,0.66,4.36c0.44,1.28,1.07,2.37,1.89,3.25c0.82,0.89,1.8,1.57,2.95,2.04
			c1.15,0.47,2.44,0.71,3.86,0.71c1.42,0,2.71-0.24,3.85-0.71c1.15-0.47,2.13-1.15,2.95-2.04c0.82-0.89,1.44-1.97,1.88-3.25
			C303.57,16.79,303.79,15.33,303.79,13.71L303.79,13.71z M307.73,13.71c0,2.01-0.32,3.85-0.96,5.54c-0.64,1.68-1.54,3.13-2.7,4.34
			c-1.16,1.21-2.56,2.15-4.19,2.82c-1.63,0.67-3.44,1.01-5.43,1.01c-1.98,0-3.79-0.34-5.42-1.01c-1.63-0.67-3.03-1.62-4.2-2.82
			c-1.17-1.21-2.08-2.66-2.72-4.34c-0.64-1.68-0.96-3.53-0.96-5.54c0-2,0.32-3.83,0.96-5.52c0.64-1.68,1.55-3.13,2.72-4.34
			c1.17-1.21,2.57-2.15,4.2-2.83c1.63-0.68,3.43-1.02,5.42-1.02c1.98,0,3.79,0.34,5.43,1.02c1.63,0.68,3.03,1.62,4.19,2.83
			c1.16,1.21,2.06,2.66,2.7,4.34C307.41,9.88,307.73,11.72,307.73,13.71L307.73,13.71z'
        />
        <path
          fill='#FFFFFF'
          d='M125.5,48.15c0-1.34-0.18-2.54-0.54-3.6c-0.36-1.06-0.89-1.96-1.56-2.69c-0.68-0.73-1.5-1.29-2.46-1.68
			c-0.96-0.39-2.03-0.58-3.21-0.58h-5.15v17.14h5.15c1.18,0,2.25-0.19,3.21-0.58c0.96-0.39,1.78-0.95,2.46-1.68
			c0.68-0.73,1.2-1.63,1.56-2.69C125.32,50.7,125.5,49.5,125.5,48.15L125.5,48.15z M128.78,48.15c0,1.67-0.27,3.19-0.8,4.57
			c-0.53,1.38-1.29,2.55-2.26,3.53c-0.97,0.98-2.13,1.74-3.49,2.27c-1.36,0.53-2.86,0.8-4.51,0.8h-8.37V36.99h8.37
			c1.65,0,3.15,0.27,4.51,0.81c1.36,0.54,2.52,1.3,3.49,2.27c0.97,0.98,1.72,2.15,2.26,3.53C128.52,44.97,128.78,46.49,128.78,48.15
			L128.78,48.15z'
        />
        <polygon
          fill='#FFFFFF'
          points='146.84,36.99 146.84,39.6 136.24,39.6 136.24,46.79 144.78,46.79 144.78,49.32 136.24,49.32
			136.24,56.71 146.85,56.71 146.84,59.32 133.02,59.32 133.02,36.99 146.84,36.99 		'
        />
        <polygon
          fill='#FFFFFF'
          points='154.18,56.61 163.72,56.61 163.72,59.32 150.98,59.32 150.98,36.99 154.18,36.99 154.18,56.61 		'
        />
        <polygon
          fill='#FFFFFF'
          points='170.2,56.61 179.74,56.61 179.74,59.32 166.99,59.32 166.99,36.99 170.2,36.99 170.2,56.61 		'
        />
        <path
          fill='#FFFFFF'
          d='M186.72,51.01h8.14l-3.38-8.79c-0.11-0.28-0.23-0.6-0.35-0.97c-0.12-0.37-0.24-0.77-0.35-1.21
			c-0.11,0.44-0.23,0.84-0.34,1.21c-0.11,0.37-0.23,0.7-0.34,0.98L186.72,51.01L186.72,51.01z M201.34,59.32h-2.49
			c-0.28,0-0.51-0.07-0.68-0.21c-0.18-0.14-0.31-0.32-0.4-0.55l-2.01-5.24h-9.91l-2.01,5.24c-0.06,0.2-0.19,0.37-0.38,0.53
			c-0.19,0.16-0.42,0.23-0.69,0.23h-2.49l8.9-22.33h3.25L201.34,59.32L201.34,59.32z'
        />
        <path
          fill='#FFFFFF'
          d='M131.13,93.1c0.24,0,0.45,0.09,0.63,0.28l1.74,1.9c-1.24,1.45-2.75,2.58-4.54,3.39
			c-1.78,0.8-3.93,1.21-6.42,1.21c-2.2,0-4.19-0.38-5.98-1.14c-1.79-0.76-3.31-1.83-4.57-3.2c-1.26-1.37-2.24-3-2.92-4.91
			c-0.68-1.9-1.03-4-1.03-6.28c0-2.29,0.36-4.38,1.08-6.28c0.72-1.9,1.74-3.54,3.05-4.9c1.31-1.36,2.88-2.42,4.71-3.18
			s3.84-1.14,6.04-1.14c2.16,0,4.07,0.35,5.74,1.06c1.67,0.71,3.13,1.64,4.37,2.81l-1.44,2.03c-0.1,0.14-0.22,0.26-0.35,0.35
			c-0.13,0.09-0.32,0.14-0.56,0.14c-0.27,0-0.58-0.13-0.94-0.4c-0.36-0.27-0.83-0.57-1.42-0.9c-0.59-0.33-1.32-0.63-2.19-0.9
			c-0.87-0.27-1.95-0.4-3.24-0.4c-1.52,0-2.92,0.27-4.2,0.8c-1.28,0.54-2.37,1.31-3.29,2.31c-0.92,1-1.63,2.23-2.14,3.68
			c-0.51,1.45-0.76,3.1-0.76,4.93c0,1.86,0.26,3.52,0.79,4.98c0.53,1.46,1.26,2.69,2.18,3.69c0.92,1,2.01,1.77,3.27,2.3
			c1.26,0.53,2.6,0.79,4.04,0.79c0.89,0,1.69-0.05,2.39-0.16c0.71-0.11,1.36-0.26,1.96-0.48c0.6-0.21,1.16-0.49,1.68-0.83
			c0.52-0.34,1.04-0.74,1.57-1.21c0.11-0.1,0.23-0.18,0.35-0.24C130.86,93.13,130.99,93.1,131.13,93.1L131.13,93.1z'
        />
        <path
          fill='#FFFFFF'
          d='M152.2,96.04c1.23,0,2.32-0.21,3.29-0.62c0.97-0.42,1.78-1,2.45-1.75c0.67-0.75,1.18-1.64,1.53-2.68
			c0.35-1.04,0.53-2.18,0.53-3.44V69.16h4.36v18.39c0,1.76-0.28,3.4-0.85,4.91c-0.56,1.51-1.37,2.81-2.41,3.91
			c-1.04,1.1-2.32,1.96-3.83,2.58c-1.51,0.62-3.2,0.93-5.08,0.93c-1.89,0-3.59-0.31-5.09-0.93c-1.5-0.62-2.78-1.48-3.83-2.58
			c-1.05-1.1-1.86-2.41-2.42-3.91c-0.56-1.51-0.85-3.15-0.85-4.91V69.16h4.36v18.39c0,1.24,0.18,2.38,0.53,3.42
			c0.35,1.04,0.86,1.93,1.53,2.69c0.67,0.75,1.49,1.34,2.47,1.76C149.87,95.83,150.97,96.04,152.2,96.04L152.2,96.04z'
        />
        <polygon
          fill='#FFFFFF'
          points='178.23,95.85 191.2,95.85 191.2,99.53 173.87,99.53 173.87,69.16 178.23,69.16 178.23,95.85 		'
        />
        <polygon
          fill='#FFFFFF'
          points='214.6,69.16 214.6,72.83 204.89,72.83 204.89,99.53 200.53,99.53 200.53,72.83 190.8,72.83
			190.8,69.16 214.6,69.16 		'
        />
        <path
          fill='#FFFFFF'
          d='M233.1,96.04c1.23,0,2.32-0.21,3.29-0.62c0.97-0.42,1.78-1,2.45-1.75c0.67-0.75,1.18-1.64,1.53-2.68
			c0.35-1.04,0.53-2.18,0.53-3.44V69.16h4.36v18.39c0,1.76-0.28,3.4-0.85,4.91c-0.56,1.51-1.37,2.81-2.41,3.91
			c-1.04,1.1-2.32,1.96-3.83,2.58c-1.51,0.62-3.2,0.93-5.08,0.93c-1.89,0-3.59-0.31-5.09-0.93c-1.5-0.62-2.78-1.48-3.83-2.58
			c-1.05-1.1-1.86-2.41-2.42-3.91c-0.57-1.51-0.85-3.15-0.85-4.91V69.16h4.36v18.39c0,1.24,0.18,2.38,0.53,3.42
			c0.35,1.04,0.86,1.93,1.53,2.69c0.67,0.75,1.49,1.34,2.47,1.76C230.77,95.83,231.87,96.04,233.1,96.04L233.1,96.04z'
        />
        <path
          fill='#FFFFFF'
          d='M259.12,83.81h4.19c1.17,0,2.2-0.14,3.09-0.42c0.89-0.28,1.63-0.68,2.22-1.21c0.59-0.52,1.04-1.14,1.34-1.85
			c0.3-0.71,0.45-1.51,0.45-2.38c0-1.76-0.58-3.1-1.74-4c-1.16-0.9-2.9-1.35-5.23-1.35h-4.34V83.81L259.12,83.81z M259.12,87v12.53
			h-4.36V69.16h8.7c1.95,0,3.63,0.2,5.04,0.59c1.41,0.4,2.58,0.96,3.5,1.7s1.6,1.63,2.04,2.68c0.44,1.04,0.66,2.21,0.66,3.49
			c0,1.07-0.17,2.07-0.5,3c-0.33,0.93-0.81,1.77-1.44,2.51c-0.63,0.74-1.4,1.38-2.31,1.91c-0.91,0.54-1.94,0.94-3.1,1.21
			c0.55,0.32,1.02,0.78,1.42,1.38l8.65,11.89h-3.89c-0.79,0-1.37-0.31-1.74-0.93l-7.62-10.64c-0.24-0.34-0.5-0.58-0.78-0.73
			c-0.28-0.15-0.71-0.22-1.27-0.22H259.12L259.12,87z'
        />
        <path
          fill='#FFFFFF'
          d='M288.75,88.23h11.07l-4.59-11.96c-0.15-0.38-0.31-0.82-0.48-1.32c-0.16-0.5-0.32-1.05-0.48-1.64
			c-0.16,0.59-0.31,1.14-0.47,1.65c-0.16,0.51-0.31,0.95-0.47,1.33L288.75,88.23L288.75,88.23z M308.62,99.53h-3.38
			c-0.38,0-0.69-0.1-0.93-0.29c-0.24-0.19-0.42-0.44-0.55-0.75l-2.73-7.13h-13.48l-2.73,7.13c-0.09,0.27-0.26,0.51-0.52,0.72
			c-0.26,0.21-0.57,0.32-0.94,0.32h-3.38l12.1-30.36h4.42L308.62,99.53L308.62,99.53z'
        />
      </g>
      <path
        fill='#FFFFFF'
        d='M31.62,39.07c-0.02-0.28-0.05-0.37-0.19-0.52c-0.18-0.19-0.78-0.27-1.04-0.13C30.1,38.56,29.93,39,30,39.4
		c0.08,0.44,0.31,0.67,0.68,0.67c0.32,0,0.52-0.08,0.71-0.26C31.59,39.61,31.64,39.44,31.62,39.07z M37.11,37.73
		c-0.06-0.25-0.16-0.32-0.67-0.48c-1.15-0.36-1.85-0.66-2.38-1.02c-0.95-0.64-1.56-1.43-1.92-2.49c-0.2-0.61-0.27-1.09-0.3-1.97
		c-0.01-0.54-0.03-0.77-0.06-0.84c-0.06-0.1-0.08-0.11-0.18-0.04c-0.1,0.08-0.2,0.29-0.43,0.93c-0.26,0.72-0.58,1.38-0.85,1.73
		c-0.39,0.52-0.43,0.59-0.54,0.87c-0.09,0.25-0.1,0.3-0.07,0.39c0.04,0.13,1.22,1.31,1.9,1.9c1.1,0.97,2.29,1.77,3.11,2.1
		c0.77,0.31,1.47,0.39,1.84,0.23C37.01,38.84,37.23,38.31,37.11,37.73z M38.41,35.5c-0.11-0.82-0.62-1.73-1.39-2.51
		c-0.2-0.21-0.48-0.45-0.6-0.52c-0.46-0.3-1.07-0.45-1.28-0.34c-0.05,0.03-0.13,0.14-0.17,0.23l-0.09,0.18l0.07,0.07
		c0.05,0.05,0.24,0.17,0.42,0.27c0.48,0.28,0.92,0.61,1.29,1.01c0.4,0.41,0.59,0.68,0.87,1.21c0.3,0.54,0.43,0.74,0.62,0.88
		C38.44,36.22,38.5,36.12,38.41,35.5z M53.53,34.63c-0.03-0.03-0.14-0.05-0.25-0.06c-0.17-0.01-0.23,0-0.48,0.13
		c-0.45,0.23-1.07,0.59-1.41,0.81c-0.45,0.27-0.68,0.49-0.82,0.78c-0.25,0.49-0.19,0.78,0.18,0.84c0.11,0.02,0.19-0.03,0.83-0.45
		c1.46-0.98,1.62-1.12,1.9-1.6C53.6,34.9,53.61,34.7,53.53,34.63z M61.63,15.3c-0.27-0.33-0.61-0.46-0.83-0.32
		c-0.15,0.09-0.3,0.34-0.36,0.57c-0.04,0.16-0.03,0.56,0.02,0.68c0.04,0.11,0.19,0.15,0.53,0.15c0.32,0,0.35-0.01,0.47-0.09
		c0.18-0.15,0.28-0.34,0.28-0.62C61.74,15.46,61.73,15.42,61.63,15.3z M63.86,31.85c-0.09-0.01-0.24,0.02-0.33,0.05
		c-0.4,0.15-0.81,0.48-0.96,0.79c-0.07,0.14-0.09,0.22-0.08,0.38l0.02,0.2l0.12,0.01c0.12,0.02,0.17-0.03,0.65-0.41
		c0.74-0.59,0.73-0.58,0.74-0.82v-0.2L63.86,31.85z M70.05,18.67c-0.03-0.17-0.06-0.25-0.13-0.32c-0.12-0.13-0.21-0.12-0.33,0
		c-0.11,0.11-0.57,0.83-0.9,1.41c-0.24,0.43-0.43,0.9-0.53,1.29c-0.09,0.37-0.09,0.38,0.18,0.51c0.21,0.11,0.37,0.14,0.5,0.09
		c0.15-0.06,0.67-0.6,0.85-0.9C70.09,20.14,70.2,19.5,70.05,18.67z M80.77,46.47c-0.48-0.97-0.93-1.3-2.11-1.52
		c-0.42-0.08-1.6-0.09-2.06-0.03c-0.72,0.1-1.55,0.32-2.16,0.55c-0.79,0.31-1.8,0.89-2.62,1.52c-0.67,0.51-3.19,2.56-4.44,3.63
		c-0.81,0.69-1.03,0.85-1.41,1.05c-0.76,0.38-1.61,0.5-2.4,0.32c-0.31-0.07-0.44-0.18-0.61-0.52c-0.11-0.24-0.13-0.28-0.14-0.62
		c0-0.2,0.01-0.65,0.05-0.99c0.09-1.11,0.06-1.44-0.16-1.71c-0.2-0.25-0.55-0.36-0.88-0.27c-0.09,0.02-0.32,0.11-0.52,0.19
		c-0.36,0.16-0.56,0.19-0.74,0.13c-0.05-0.03-0.19-0.12-0.31-0.21c-0.31-0.25-0.54-0.31-1.1-0.31c-0.54,0-0.69,0.04-0.77,0.23
		c-0.03,0.07-0.06,0.21-0.06,0.32c0,0.16,0.02,0.25,0.18,0.57c0.06,0.14,0.14,0.32,0.2,0.47c0.03,0.07,0.06,0.14,0.08,0.18
		c0.22,0.55,0.38,0.8,1.38,2.06c0.43,0.53,1,1.27,1.27,1.61c0.27,0.35,0.56,0.68,0.63,0.75c0.28,0.24,0.67,0.37,0.92,0.31
		c0.05-0.01,0.24-0.16,0.41-0.33c0.17-0.16,0.37-0.34,0.44-0.39c0.19-0.13,0.4-0.11,0.59,0.02c0.02,0.01,0.04,0.03,0.05,0.05
		c0.02,0.01,0.04,0.03,0.06,0.05c0.11,0.15,0.11,0.26-0.03,0.68c-0.16,0.47-0.21,0.79-0.18,1.29c0.02,0.54,0.07,0.65,0.38,0.83
		c0.45,0.27,1.07,0.46,1.61,0.49c0.34,0.03,0.91-0.01,1.64-0.09c0.37-0.04,0.78-0.09,1.22-0.16c1.07-0.17,1.3-0.15,2.13,0.24
		c0.7,0.32,0.9,0.36,1.49,0.25c1.2-0.23,2.32-0.8,3.19-1.65c0.38-0.36,0.39-0.4,0.4-1.48c0.01-1.07-0.01-1,0.43-1.18
		c1.15-0.44,2.27-1.16,3.18-2.03c0.5-0.48,0.81-0.86,0.99-1.22c0.18-0.36,0.23-0.58,0.26-1.02C81.26,47.81,81.12,47.2,80.77,46.47z
		 M69.74,51.25c-1.32,1.01-1.41,1.07-1.66,1.07c-0.18,0-0.59-0.13-0.69-0.21c-0.02-0.03-0.04-0.11-0.04-0.21v-0.17l0.53-0.56
		c0.72-0.74,1.85-1.82,2.11-2.01c0.12-0.08,0.42-0.26,0.67-0.39c0.42-0.22,0.48-0.24,0.62-0.23c0.1,0.01,0.22,0.05,0.31,0.11
		c0.36,0.24,0.39,0.84,0.04,1.16c-0.07,0.06-0.28,0.23-0.46,0.36C70.99,50.29,70.34,50.78,69.74,51.25z M73.53,50.92
		c-0.08,0.17-0.51,0.59-1.21,1.17c-0.58,0.48-0.63,0.51-0.76,0.49c-0.14-0.03-0.33-0.15-0.39-0.26c-0.02-0.05-0.05-0.15-0.05-0.23
		c0-0.13,0.03-0.17,0.48-0.65c0.26-0.28,0.66-0.69,0.87-0.91l0.4-0.4l0.25-0.02c0.35-0.03,0.34-0.03,0.41,0.13
		C73.64,50.47,73.63,50.71,73.53,50.92z M81.23,99.58c0.2-1.22,0.36-2.45,0.48-3.7c0.14-1.5,0.21-3.02,0.21-4.56
		c0-0.77-0.02-1.54-0.05-2.3c0.22-0.18,0.5-0.4,0.71-0.52c0.12-0.07,0.61-0.35,1.32-0.75c0.56-0.31,1.26-0.7,2.04-1.12
		c0.83-0.46,1.66-0.94,1.86-1.08c1.59-1.07,2.7-2.65,3.31-4.68c0.19-0.63,0.33-1.27,0.33-1.56c0-0.32-0.11-0.83-0.21-0.97
		c-0.09-0.13-0.27-0.23-0.46-0.27l-0.16-0.02l-0.08,0.28c-0.05,0.15-0.14,0.48-0.2,0.73c-0.37,1.52-1.11,2.43-2.58,3.13
		c-0.94,0.46-1.97,0.72-2.93,0.76c-0.25,0.01-0.48,0-0.71-0.01c-1.25-0.1-2.09-0.61-2.58-1.55c-0.51-1.01-0.77-3.03-0.54-4.47
		c0.09-0.58,0.15-0.78,0.31-1.1c0.25-0.47,0.78-0.88,1.3-0.98c0.45-0.09,1.11,0,1.5,0.24c0.41,0.23,0.83,0.81,0.98,1.39
		c0.1,0.35,0.26,0.61,0.48,0.79c0.08,0.06,0.17,0.12,0.26,0.16c0.45,0.22,1.07,0.06,2.47-0.62l0.79-0.39h0.3
		c0.28,0,0.31,0.01,0.63,0.16c0.56,0.26,0.6,0.27,0.62,0.21c0.01-0.03,0.03-0.13,0.03-0.23c0-0.16-0.01-0.19-0.16-0.31
		c-0.57-0.51-0.68-0.63-0.79-0.87c-0.2-0.41-0.23-0.88-0.06-1.21c0.07-0.15,0.18-0.25,0.4-0.4c0.29-0.19,0.39-0.42,0.46-1.01
		c0.04-0.35,0.02-0.62-0.1-0.95c-0.13-0.39-0.28-0.63-0.83-1.3c-0.36-0.45-0.41-0.57-0.41-0.88c0-0.41,0.17-0.8,0.5-1.19
		c0.47-0.54,0.62-0.89,0.62-1.47c0-0.44-0.03-0.54-0.34-1c-0.1-0.15-0.21-0.36-0.24-0.45c-0.14-0.38-0.04-0.93,0.2-1.18
		c0.16-0.17,0.96-0.49,1.83-0.72c1.27-0.34,1.71-0.86,1.79-2.08c0.03-0.38-0.02-0.52-0.2-0.62c-0.09-0.05-0.1-0.05-0.26,0.04
		c-0.66,0.39-1.05,0.51-2.25,0.71c-1.47,0.25-2.19,0.51-3.77,1.4c-0.51,0.29-0.88,0.5-1.18,0.65c-0.52,0.26-0.78,0.35-1.06,0.39
		c-0.57,0.09-1.11-0.17-1.55-0.74c-0.23-0.32-0.47-0.55-0.67-0.64c-0.2-0.1-0.52-0.13-0.63-0.05c-0.13,0.08-0.26,0.32-0.34,0.63
		c-0.09,0.39-0.06,0.49,0.25,0.77c0.13,0.12,0.43,0.34,0.68,0.49c0.63,0.39,0.91,0.61,1.35,1.06c0.54,0.55,0.75,0.65,1.46,0.7
		c0.19,0.01,0.4,0.03,0.61,0.07c0.52,0.08,1.06,0.21,1.2,0.31c0.05,0.02,0.12,0.12,0.17,0.2c0.35,0.59-0.09,1.64-1.12,2.68
		c-0.07,0.08-0.16,0.16-0.23,0.23c-0.48,0.44-1.02,0.78-1.6,1.02c-0.45,0.18-0.64,0.23-1.23,0.25c-0.94,0.04-1.12,0.1-1.85,0.56
		c-0.24,0.15-0.54,0.34-0.69,0.41c-0.29,0.16-0.88,0.37-1.2,0.41c-0.46,0.07-0.93-0.08-1.36-0.45c-0.13-0.1-0.24-0.23-0.36-0.36
		c-0.55-0.66-1.17-0.95-2.23-1.07c-0.24-0.03-0.58-0.08-0.74-0.13c-0.16-0.04-1.89-0.61-3.83-1.26c-6.29-2.12-6.09-2.05-8.21-2.95
		c-2.05-0.87-2.7-1.28-3.31-2.09c-0.39-0.53-0.59-1.01-0.84-2.02c-0.3-1.27-0.43-2.21-0.4-3.19c0.02-0.93,0.12-1.38,0.34-1.58
		c0.28-0.25,0.52-0.24,1.13,0.04c0.41,0.19,0.44,0.2,0.72,0.21c0.26,0,0.32-0.02,0.54-0.12c0.42-0.2,0.59-0.22,1.64-0.24l0.96-0.01
		l-0.08-0.11c-0.04-0.06-0.52-0.64-1.07-1.28c-0.54-0.65-1.12-1.34-1.26-1.52c-0.36-0.46-0.39-0.48-0.86-0.44
		c-0.51,0.04-0.99,0.03-1.47-0.04c-0.67-0.09-2.17-0.39-2.25-0.45c-0.1-0.06-0.18-0.25-0.18-0.4c0-0.16,0.01-0.16,0.74-0.41
		c0.54-0.17,0.56-0.19,0.75-0.38c0.25-0.25,0.34-0.5,0.36-1.07c0.03-0.73-0.12-1.2-0.67-2.18c-0.18-0.32-0.32-0.57-0.43-0.77
		c-0.22-0.43-0.3-0.68-0.4-1.15c-0.11-0.46-0.1-0.54,0.04-0.63c0.27-0.17,1.18-0.21,1.74-0.06c0.42,0.1,0.99,0.19,1.26,0.17
		c0.21-0.01,0.26-0.03,0.47-0.17c0.14-0.09,0.35-0.25,0.47-0.36c0.13-0.12,0.42-0.3,0.63-0.43c0.53-0.3,1.07-0.68,1.81-1.27
		c0.33-0.26,0.95-0.74,1.38-1.07c1.3-1,2.07-1.61,2.61-2.06c1.6-1.35,2.95-2.8,3.96-4.23c0.35-0.5,0.46-0.74,0.68-1.37
		c0.19-0.59,0.41-1.02,0.7-1.41c0.11-0.16,0.55-0.63,1-1.08c0.45-0.45,0.86-0.88,0.95-1.01c0.2-0.31,0.36-0.68,0.46-1.03
		c0.14-0.59,0.08-1.9-0.12-2.31c-0.08-0.15-0.24-0.28-0.45-0.36c-0.15-0.04-0.56-0.05-0.7,0c-0.17,0.07-0.51,0.32-0.73,0.57
		c-0.28,0.29-0.46,0.61-0.67,1.13c-0.09,0.24-0.23,0.55-0.31,0.7c-0.19,0.32-0.59,0.76-1.01,1.09c-0.18,0.13-0.55,0.45-0.83,0.7
		c-0.76,0.66-0.85,0.73-1.31,1.04c-0.9,0.59-1.09,0.84-0.93,1.14c0.03,0.05,0.2,0.25,0.39,0.44c0.42,0.41,0.43,0.46,0.2,0.69
		c-0.4,0.39-0.62,0.38-2.09-0.09c-0.86-0.28-1.06-0.27-1.63,0.13c-0.44,0.3-0.58,0.38-1.29,0.74l-0.63,0.32l-0.52,0.03
		c-0.55,0.03-0.72,0.07-0.74,0.19c0,0.03,0.05,0.18,0.13,0.34c0.16,0.31,0.17,0.36,0.09,0.46c-0.18,0.26-1.6,0.42-2.5,0.29
		c-0.18-0.02-0.52-0.04-0.76-0.04c-0.53,0-0.78,0.07-1.34,0.38c-0.7,0.39-1.26,0.57-1.69,0.55c-0.25-0.01-0.26-0.02-0.37-0.15
		c-0.06-0.07-0.18-0.3-0.28-0.51c-0.33-0.78-0.61-1.06-1.04-1.06c-0.47,0-1.04,0.55-1.2,1.18c-0.08,0.29-0.07,0.52,0.03,0.61
		c0.04,0.04,0.42,0.24,0.83,0.45c0.43,0.21,0.87,0.47,1.02,0.58c0.48,0.35,0.84,0.52,1.48,0.69c0.53,0.13,0.9,0.29,1.05,0.45
		l0.13,0.13l-0.06,0.1c-0.04,0.06-0.12,0.16-0.2,0.23l-0.13,0.12l-0.61-0.02c-0.69-0.02-0.97-0.07-1.41-0.28
		c-0.29-0.14-0.47-0.25-0.77-0.51c-0.25-0.22-0.45-0.3-0.72-0.3c-0.25,0-0.4,0.04-0.8,0.23c-0.53,0.24-0.9,0.31-1.97,0.4
		c-0.76,0.06-0.89,0.13-0.97,0.47c-0.04,0.17,0.01,0.55,0.1,0.72c0.07,0.13,0.23,0.29,0.42,0.41c0.23,0.16,1.04,0.84,1.09,0.93
		c0.11,0.17,0.04,0.7-0.14,1.16c-0.02,0.05-0.04,0.09-0.06,0.13c-0.13,0.28-0.37,0.59-0.64,0.83c-0.25,0.23-0.33,0.36-0.33,0.59
		c0,0.23,0.07,0.41,0.2,0.56c0.17,0.2,0.35,0.26,0.76,0.24c0.5-0.03,0.88-0.15,1.7-0.53c0.52-0.25,1-0.4,1.42-0.46
		c0.75-0.1,1.36,0.1,1.91,0.6c0.23,0.2,0.41,0.43,0.56,0.68c0.33,0.52,0.51,1.15,0.55,1.87l0.02,0.41l-0.12,0.14
		c-0.06,0.08-0.34,0.38-0.62,0.68c-0.8,0.85-1.46,1.6-1.71,1.92c-0.51,0.67-0.58,0.71-1.29,0.8c-0.59,0.08-0.82,0.15-1.09,0.32
		c-0.18,0.12-0.44,0.44-0.53,0.64c-0.04,0.1-0.04,0.11,0.14,0.3c0.1,0.11,0.25,0.26,0.32,0.33c0.19,0.17,0.24,0.29,0.22,0.54
		c-0.04,0.45-0.38,0.92-0.81,1.15c-0.55,0.29-1.52,0.62-2.26,0.77c-0.63,0.13-1.13,0.17-1.8,0.15c-1.02-0.04-1.84-0.23-2.82-0.65
		c-0.34-0.15-1.22-0.61-1.54-0.82c-0.25-0.16-0.64-0.54-0.73-0.7c-0.14-0.27-0.06-0.54,0.21-0.74c0.19-0.14,0.36-0.12,0.84,0.12
		c0.47,0.23,0.8,0.32,1.43,0.4c1.04,0.12,1.99,0.1,2.83-0.05c0.61-0.11,0.65-0.16,0.85-0.75c0.17-0.52,0.54-1.29,0.97-2
		c0.4-0.68,0.43-0.78,0.43-1.41c0-0.44-0.01-0.5-0.07-0.58c-0.14-0.2-0.26-0.23-0.77-0.2c-0.55,0.03-1.01,0.11-2.02,0.38
		c-0.9,0.24-1.32,0.32-2.08,0.37c-1.33,0.09-2.69-0.08-3.96-0.49c-0.77-0.25-1.21-0.52-1.74-1.05c-0.73-0.74-1.33-1.64-1.62-2.45
		c-0.23-0.62-0.27-0.72-0.47-1.02c-0.32-0.47-0.76-0.84-1.29-1.08c-0.13-0.06-0.5-0.21-0.83-0.31c-1.41-0.47-1.91-0.69-2.42-1.07
		c-0.55-0.42-1.1-1.02-1.58-1.74c-0.11-0.16-0.17-0.26-0.23-0.39c-0.05-0.13-0.09-0.27-0.17-0.53c-0.09-0.29-0.24-0.47-0.44-0.51
		c-0.15-0.03-0.19,0.01-0.36,0.33c-0.12,0.23-0.19,0.48-0.2,0.74c-0.01,0.2,0.01,0.41,0.06,0.62c0.09,0.34,0.22,0.56,0.55,0.93
		c0.28,0.3,0.37,0.48,0.4,0.83c0.05,0.5-0.7,1.83-1.5,2.64c-0.33,0.34-0.49,0.41-0.82,0.41c-0.27,0-0.49-0.11-0.77-0.39
		c-0.34-0.33-0.54-0.37-1.14-0.18c-0.42,0.13-0.61,0.1-0.89-0.12c-0.4-0.32-0.8-2.15-0.85-3.91c0-0.1,0-0.21,0-0.32
		c-0.01-1.17,0.16-2.12,0.48-2.76c0.12-0.24,0.21-0.36,0.44-0.59c0.25-0.25,0.36-0.32,0.69-0.49c0.49-0.25,0.96-0.56,1.1-0.73
		c0.3-0.33,0.35-0.62,0.19-1.09c-0.06-0.17-0.12-0.42-0.15-0.53c-0.05-0.28-0.05-0.94,0-1.26c0.08-0.46,0.19-0.63,0.7-1.04
		c0.11-0.08,0.2-0.2,0.28-0.36l0.12-0.24l0.02-1.01l0.02-1.02l0.09-0.17c0.14-0.28,0.36-0.46,0.88-0.72
		c0.34-0.17,0.72-0.29,1.07-0.33c0.18-0.02,0.36-0.06,0.41-0.08c0.13-0.08,0.26-0.22,0.37-0.43c0.2-0.41,0.48-0.74,0.91-1.08
		c0.47-0.37,0.82-0.48,1.26-0.4c0.12,0.02,0.48,0.12,0.78,0.22c0.51,0.16,0.59,0.18,0.89,0.18c0.3,0,0.34,0,0.47-0.09
		c0.28-0.18,0.32-0.35,0.58-2.09c0.07-0.47,0.14-0.9,0.16-0.98c0.05-0.2,0.31-0.43,0.73-0.64c0.21-0.1,0.45-0.2,0.61-0.24
		c0.35-0.07,0.82-0.04,1.21,0.08c0.62,0.18,1.13,0.18,1.6,0.01c0.65-0.24,0.9-0.27,1.07-0.15c0.2,0.15,0.29,0.4,0.46,1.4
		c0.18,1.1,0.32,1.51,0.61,1.92c0.55,0.77,0.68,1.52,0.54,3.09c-0.14,1.42-0.14,1.37-0.03,1.56c0.22,0.37,0.6,0.66,1.52,1.18
		c0.35,0.19,1.12,0.73,1.67,1.16c0.69,0.53,1.49,0.67,2.23,0.4c0.23-0.09,0.57-0.32,1.11-0.74c0.82-0.64,0.9-0.68,1.85-0.71
		c0.38-0.02,0.93-0.05,1.22-0.08c0.52-0.05,0.54-0.05,0.92-0.23c0.51-0.23,0.71-0.36,1.06-0.71c0.65-0.64,1.2-0.93,2.47-1.28
		c1.05-0.29,1.74-0.76,2.26-1.55c0.43-0.64,0.65-0.83,2.12-1.71c0.39-0.23,0.82-0.51,0.96-0.61c0.55-0.41,1.4-1.37,1.65-1.89
		c0.14-0.25,0.14-0.51,0.04-0.73c-0.12-0.25-0.37-0.43-1.02-0.76c-0.33-0.16-0.6-0.31-0.6-0.33c0-0.07,0.14-0.26,0.25-0.34
		c0.2-0.15,0.61-0.28,1.42-0.43c0.14-0.04,0.23-0.07,0.3-0.14c0.1-0.09,0.1-0.1,0.1-0.51c0-0.22-0.02-0.55-0.06-0.73
		c-0.03-0.18-0.06-0.5-0.07-0.72c-0.02-0.35,0-0.45,0.06-0.68c0.1-0.39,0.24-0.62,0.85-1.48c0.57-0.79,0.79-1.16,0.89-1.51
		c0.12-0.4,0.04-1.19-0.14-1.47c-0.18-0.28-0.53-0.41-0.73-0.28c-0.14,0.09-0.19,0.2-0.39,0.87c-0.21,0.67-0.4,1.14-0.65,1.64
		c-0.16,0.3-0.24,0.42-0.46,0.64c-0.44,0.44-0.69,0.5-1.14,0.29c-0.51-0.25-0.84-0.25-1.92-0.02c-0.37,0.08-0.73,0.14-0.81,0.14
		c-0.43,0-0.8-0.16-1.4-0.61c-0.86-0.65-1.32-1.12-1.77-1.8c-0.25-0.37-0.43-0.56-0.65-0.68c-0.19-0.09-0.48-0.16-1.46-0.32
		c-0.93-0.15-1.03-0.16-1.64-0.16c-0.71,0-0.99,0.04-1.91,0.26c-0.54,0.13-0.75,0.22-1.44,0.61c-1.3,0.73-1.86,0.94-2.58,0.94
		c-0.7,0-0.96-0.45-0.96-1.62c0-0.5,0-0.55,0.09-0.83c0.2-0.61,0.5-1.06,1.03-1.56c0.66-0.64,1.54-1.11,2.69-1.45
		c0.54-0.15,0.63-0.17,1.54-0.28c0.71-0.08,1.52-0.12,1.88-0.06c0.08,0,0.47,0.06,0.86,0.11c0.61,0.07,0.85,0.09,1.57,0.09
		c1.22,0,1.35-0.04,1.62-0.54c0.34-0.61,0.95-1.34,1.4-1.66c0.63-0.45,1.2-0.63,2.44-0.76c0.98-0.1,1.64-0.25,2.47-0.55
		c1.11-0.41,1.65-0.4,2.97,0.05c1.3,0.45,1.76,0.49,2.84,0.32c0.3-0.05,0.75-0.13,1-0.16c0.55-0.09,1.09-0.09,1.37-0.02
		c0.48,0.13,0.83,0.39,1.45,1.08c0.5,0.57,0.5,0.58,0.44,1.15c-0.05,0.48-0.12,0.71-0.3,1.09c-0.2,0.43-0.52,0.75-0.92,0.96
		c-0.29,0.14-0.41,0.17-1.25,0.37c-0.87,0.2-1.08,0.33-1.1,0.75c-0.03,0.42,0.12,0.68,0.5,0.88c0.29,0.15,0.54,0.2,1.15,0.25
		c1.32,0.08,1.48,0.14,1.95,0.63c0.33,0.35,0.4,0.45,0.39,0.7c0,0.24-0.07,0.45-0.3,0.94c-0.18,0.36-0.22,0.43-0.61,0.84
		c-0.24,0.24-0.44,0.48-0.45,0.53c-0.03,0.12,0.01,0.24,0.13,0.41c0.17,0.25,0.4,0.77,0.48,1.06c0.06,0.26,0.09,0.52,0.17,1.29
		c0.06,0.64,0.23,0.84,1.05,1.23c1.05,0.5,2.1,1.12,3.79,2.2c1.13,0.74,1.41,0.97,1.55,1.32c0.1,0.28,0.1,0.53,0,0.68
		c-0.23,0.35-0.58,0.36-1.29,0.04c-1.11-0.5-1.35-0.59-1.57-0.63c-0.66-0.11-1.07,0.26-1.07,0.96c0,0.19,0.02,0.26,0.09,0.38
		c0.23,0.4,0.38,0.9,0.48,1.62c0.18,1.31,0.37,1.76,1.3,3.09c1.85,2.64,2.74,3.96,3.17,4.67c0.64,1.06,1.13,2.17,1.44,3.25
		c0.17,0.62,0.23,0.92,0.31,1.9c0.03,0.45,0.14,1.63,0.22,2.64c0.22,2.53,0.31,3.41,0.39,3.63c0.15,0.44,0.52,1.04,1.22,1.96
		c0.48,0.64,1.13,1.43,1.96,2.42c0.9,1.08,1.25,1.42,1.58,1.6c0.29,0.16,0.35,0.14,0.29-0.07c-0.03-0.08-0.17-0.28-0.39-0.52
		c-0.6-0.67-0.76-0.9-0.81-1.18c-0.09-0.5,0.7-0.88,1.63-0.79c0.02,0,0.05,0,0.07,0c3.44,1.27,6.78,2.75,10,4.41
		c0.08,0.04,0.16,0.08,0.24,0.12l0,0v43.64 M84.46,73.58c0.14-0.28,0.93-0.99,1.41-1.28c0.01-0.01,0.02-0.01,0.03-0.02
		c0.47-0.28,0.73-0.34,0.98-0.23c0.18,0.07,0.31,0.2,0.42,0.43c0.22,0.43,0.18,0.56-0.27,1.01c-0.48,0.46-0.83,0.72-1.24,0.85
		c-0.22,0.07-0.45,0.11-0.73,0.13c-0.29,0.02-0.34,0.01-0.43-0.05C84.42,74.29,84.33,73.83,84.46,73.58z M18.11,70.13
		c-0.12,0.14-0.34,0.25-0.48,0.25c-0.05,0-0.14-0.03-0.21-0.07c-0.59-0.34-0.79-0.39-1.41-0.39c-0.52,0.01-1,0.07-2.27,0.35
		c-0.4,0.09-0.77,0.15-0.81,0.14c-0.14-0.04-0.3-0.2-0.39-0.39c-0.19-0.42-0.15-0.55,0.23-0.76c0.64-0.34,0.92-0.41,1.56-0.38
		c0.73,0.04,0.76,0.02,1.56-0.72l0.44-0.4l0.17,0.01c0.09,0.01,0.28,0.07,0.44,0.15c0.36,0.18,0.83,0.64,1.03,1.03
		c0.16,0.32,0.25,0.63,0.25,0.87C18.21,69.97,18.2,70.02,18.11,70.13z M14.56,67.36c0.04-0.15,0.33-0.53,0.44-0.58
		c0.09-0.04,0.4-0.08,0.48-0.06c0.04,0.01,0.1,0.04,0.13,0.06c0.08,0.07,0.23,0.38,0.28,0.58c0.09,0.36-0.08,0.58-0.61,0.75
		c-0.25,0.08-0.34,0.1-0.4,0.07C14.65,68.1,14.48,67.64,14.56,67.36z M19.54,71.56c-0.61,0.3-1.01,0.03-0.68-0.45
		c0.04-0.05,0.14-0.24,0.23-0.4c0.38-0.73,0.94-1.17,1.18-0.91c0.05,0.06,0.07,0.14,0.08,0.34C20.38,70.78,20.09,71.29,19.54,71.56z
		 M29.08,64.78c-0.23-0.03-0.43-0.08-0.52-0.12c-0.25-0.13-0.38-0.39-0.36-0.75c0-0.04,0.04-0.11,0.08-0.15
		c0.07-0.07,0.09-0.07,0.8-0.05c0.4,0.01,0.91,0.05,1.13,0.07c1.05,0.14,1.86,0.41,2.45,0.8c0.3,0.2,0.49,0.42,0.65,0.74
		c0.21,0.45,0.18,0.74-0.11,0.98c-0.32,0.26-0.53,0.31-0.89,0.2c-0.46-0.14-0.94-0.42-1.63-0.98
		C30.08,65.03,29.73,64.87,29.08,64.78z M35.95,48.85c0.06-0.06,0.14-0.13,0.17-0.14c0.09-0.05,0.33-0.05,0.45,0
		c0.21,0.08,0.39,0.33,0.44,0.61c0.04,0.18-0.03,0.29-0.22,0.39c-0.3,0.15-0.51,0.12-0.73-0.12C35.8,49.28,35.76,49.02,35.95,48.85z
		 M34.37,53.19c0.04-0.05,0.1-0.1,0.15-0.12c0.13-0.03,0.42-0.03,0.62,0.02c0.46,0.09,0.73,0.68,0.46,0.97
		c-0.2,0.23-0.66,0.3-0.88,0.15c-0.14-0.09-0.32-0.45-0.38-0.73C34.31,53.3,34.31,53.27,34.37,53.19z M33.87,50.65
		c0,0.2-0.07,0.37-0.16,0.42c-0.11,0.06-0.62,0.17-0.76,0.17c-0.17,0-0.39-0.15-0.53-0.37c-0.18-0.29-0.2-0.52-0.06-0.65
		c0.05-0.05,0.31-0.13,0.49-0.15c0,0,0.2,0.04,0.43,0.09c0.35,0.07,0.42,0.09,0.5,0.18C33.85,50.44,33.87,50.49,33.87,50.65z
		 M31.15,47.43c0.29-0.32,0.75-0.44,1.34-0.36c0.66,0.1,1,0.35,1,0.74c0,0.21-0.09,0.38-0.3,0.57c-0.4,0.37-1.15,0.53-1.72,0.35
		c-0.28-0.09-0.41-0.2-0.46-0.41C30.9,47.94,30.96,47.65,31.15,47.43z M25.69,56.31c1.16,0.04,1.29,0.04,1.37-0.01
		c0.05-0.03,0.1-0.08,0.12-0.13c0.02-0.04,0.18-0.6,0.35-1.25c0.46-1.72,0.48-1.77,0.72-1.94c0.15-0.1,0.39-0.1,0.66-0.03
		c0.27,0.08,0.41,0.24,0.55,0.6c0.14,0.38,0.15,0.59,0.04,0.71c-0.05,0.05-0.15,0.11-0.21,0.14c-0.11,0.05-0.45,0.35-0.45,0.4
		c0,0.01,0.1,0.07,0.23,0.15c0.68,0.35,1.25,0.37,2.85,0.13c0.68-0.1,1.14-0.09,2.19,0.03c0.94,0.1,1.27,0.09,1.85-0.1
		c0.4-0.13,0.78-0.13,1.06,0c0.25,0.11,0.37,0.23,0.64,0.58c0.58,0.76,1.28,1.09,2.55,1.2c0.85,0.08,1.52,0.19,1.63,0.26
		c0.14,0.09,0.25,0.29,0.22,0.45c-0.04,0.21-0.24,0.48-0.45,0.63c-1.05,0.73-2.36,1.11-3.44,1.01c-0.42-0.04-0.98-0.16-1.24-0.27
		c-0.25-0.11-1.04-0.57-1.37-0.81c-0.86-0.62-1.56-0.85-2.5-0.85c-0.66,0-0.92,0.06-2.84,0.61c-1.28,0.37-1.26,0.37-2,0.1
		c-0.48-0.17-0.95-0.15-1.33,0.03c-1.27,0.64-1.78,2.06-1.35,3.76c0.27,1.05,0.85,2.24,1.4,2.85c0.28,0.31,0.39,0.73,0.23,0.88
		c-0.23,0.2-0.47,0.2-1.04-0.02c-0.53-0.19-1.67-0.75-2.21-1.08c-0.99-0.6-1.17-0.74-1.81-1.37c-0.33-0.33-0.82-0.86-1.08-1.16
		c-0.27-0.3-0.57-0.64-0.69-0.75c-0.43-0.43-1.04-0.75-1.62-0.87c-0.5-0.11-1.58-0.07-1.85,0.07c-0.23,0.13-0.48,0.5-0.48,0.73
		c0,0.11,0.02,0.15,0.11,0.24c0.13,0.13,0.78,0.62,1.09,0.84c0.39,0.29,0.39,0.29,0.39,0.72c0,0.35,0,0.38-0.08,0.47
		c-0.16,0.17-0.22,0.17-1.79,0.15c-1.59-0.03-1.67-0.02-1.87,0.15c-0.19,0.15-0.24,0.27-0.27,0.7c-0.03,0.34-0.04,0.39-0.13,0.46
		c-0.07,0.09-0.12,0.09-0.3,0.09c-0.21,0-0.23,0-0.36-0.13c-0.12-0.11-0.13-0.15-0.13-0.29c0-0.3,0.11-0.66,0.34-1.11
		c0.3-0.6,0.38-0.81,0.52-1.35c0.2-0.8,0.48-1.26,1.13-1.9c0.53-0.52,1.08-0.94,1.64-1.22c0.54-0.27,0.98-0.4,1.7-0.49
		c0.25-0.03,0.6-0.09,0.78-0.14c0.36-0.09,0.99-0.34,1.26-0.49c0.21-0.11,0.85-0.45,1.54-0.81c0.75-0.39,1.35-0.55,2.08-0.6
		C24.25,56.28,24.98,56.29,25.69,56.31z M24.7,47.65c0.13,0.35,0.14,0.4,0.1,0.52c-0.02,0.07-0.1,0.2-0.2,0.29
		c-0.14,0.14-0.17,0.15-0.33,0.15c-0.15,0-0.25-0.04-0.57-0.19c-0.47-0.24-0.61-0.26-0.77-0.09c-0.14,0.15-0.24,0.5-0.24,0.82
		c0,0.33,0.11,0.99,0.21,1.22c0.14,0.32,0.43,0.72,0.99,1.33c0.65,0.71,0.85,0.95,1.01,1.29c0.22,0.45,0.33,0.93,0.26,1.17
		c-0.08,0.32-0.94,0.71-1.52,0.71c-0.76,0-1.42-0.44-2.22-1.45c-0.48-0.62-0.93-1.49-1.22-2.35c-0.28-0.85-0.27-1.22,0.06-1.7
		c0.33-0.5,0.42-0.78,0.31-1.11c-0.03-0.09-0.17-0.32-0.33-0.54c-0.31-0.4-0.39-0.6-0.39-0.91c0-0.26,0.11-0.51,0.45-1.03
		c0.33-0.5,0.5-0.71,0.91-1.13c0.37-0.38,1.58-1.49,1.7-1.57c0.1-0.06,0.33-0.05,0.49,0.02c0.17,0.08,0.32,0.23,0.34,0.34
		c0.05,0.23,0.09,0.91,0.09,1.48c0.01,0.92,0.12,1.35,0.54,2.03C24.48,47.13,24.62,47.44,24.7,47.65z M49.81,19.71
		c0.12-0.24,0.49-0.6,0.76-0.73c0.35-0.17,0.53-0.2,1.27-0.2c0.75,0,0.99-0.05,1.46-0.23c0.16-0.06,0.36-0.15,0.45-0.21
		C54.27,18.01,54.3,18,54.46,18c0.1,0,0.23,0.03,0.29,0.06c0.16,0.09,0.23,0.16,0.32,0.35c0.14,0.32,0.12,0.73-0.05,0.85
		c-0.1,0.06-0.32,0.1-1.49,0.29c-1.28,0.2-1.56,0.31-2.1,0.79c-0.1,0.09-0.28,0.2-0.4,0.26c-0.19,0.09-0.27,0.11-0.55,0.12
		c-0.41,0.01-0.5-0.04-0.64-0.32C49.72,20.12,49.71,19.91,49.81,19.71z M47.77,26.67c0.72-0.13,1.08-0.25,1.85-0.61
		c0.8-0.38,1.03-0.45,2.16-0.67c1.93-0.38,3.08-0.78,4.05-1.4c0.36-0.24,0.5-0.25,1.06-0.18c0.2,0.02,0.37,0.07,0.51,0.14
		c0.39,0.19,0.67,0.6,0.67,1.01c0,0.16-0.01,0.2-0.11,0.31c-0.16,0.18-0.28,0.23-0.61,0.25c-0.23,0.02-0.3,0.04-0.37,0.1
		c-0.05,0.05-0.09,0.11-0.09,0.14c0,0.1-0.11,0.16-0.57,0.32c-0.59,0.21-1.13,0.33-1.57,0.37c-0.2,0.02-0.42,0.05-0.49,0.08
		c-0.2,0.08-0.5,0.31-0.82,0.61c-0.18,0.17-0.38,0.32-0.52,0.39c-0.21,0.11-0.24,0.12-0.62,0.12c-0.38,0-0.42-0.01-0.81-0.15
		c-0.54-0.19-1.19-0.39-1.3-0.39c-0.04,0-0.22,0.09-0.37,0.2c-0.8,0.53-1.67,0.82-2.56,0.86c-0.39,0.02-0.41,0.02-0.49-0.06
		c-0.08-0.08-0.16-0.35-0.16-0.52C46.62,27.14,47.06,26.8,47.77,26.67z M46.53,17.44c0.08,0.08,0.19,0.55,0.16,0.69
		c-0.01,0.07-0.09,0.16-0.23,0.28c-0.12,0.1-0.32,0.29-0.46,0.42c-0.41,0.4-0.61,0.93-0.5,1.27c0.06,0.2,0.39,0.63,0.69,0.91
		c0.36,0.34,0.49,0.59,0.5,1.01c0.02,0.41-0.06,0.59-0.29,0.69c-0.14,0.06-0.2,0.06-0.51,0.05c-0.54-0.04-0.9-0.21-1.16-0.56
		c-0.18-0.25-0.26-0.49-0.32-1.01c-0.05-0.48-0.11-0.65-0.25-0.81c-0.18-0.18-0.36-0.19-0.8-0.02c-0.23,0.08-0.63,0.09-1.1,0.02
		c-0.22-0.04-0.6-0.05-1.13-0.05c-1.06,0-1.08,0-1.23,0.54c-0.23,0.77-0.76,1.46-1.45,1.88c-0.42,0.25-1.17,0.47-1.38,0.4
		c-0.2-0.08-0.35-0.6-0.25-0.9c0.06-0.17,0.25-0.38,0.45-0.49c0.2-0.1,0.42-0.16,1.08-0.31c0.3-0.06,0.58-0.14,0.63-0.17
		c0.23-0.16,0.18-0.39-0.12-0.72c-0.24-0.26-0.28-0.32-0.28-0.48c0-0.16,0.2-0.47,0.44-0.72c0.27-0.27,0.67-0.47,1.28-0.63
		c0.83-0.22,1.28-0.41,1.68-0.71c0.13-0.1,0.3-0.21,0.38-0.24c0.18-0.08,0.72-0.12,1.2-0.1c0.36,0.03,1.2,0,1.72-0.07
		c0.17-0.02,0.44-0.08,0.6-0.13C46.23,17.36,46.43,17.35,46.53,17.44z M80.16,28.73c-0.21,0.42-0.81,0.43-1.31,0.02
		c-0.09-0.08-0.12-0.13-0.12-0.22c0-0.14,0.08-0.31,0.19-0.42c0.06-0.06,0.1-0.07,0.41-0.05c0.18,0,0.42,0.04,0.54,0.07
		C80.2,28.21,80.31,28.43,80.16,28.73z'
      />
      <g>
        <path
          fill='#FFFFFF'
          d='M31.69,79.26c0.24,0.24,0.51,0.42,0.83,0.56c0.32,0.14,0.66,0.21,1.02,0.21c0.37,0,0.72-0.07,1.04-0.21
			c0.33-0.14,0.61-0.33,0.85-0.56c0.24-0.24,0.43-0.51,0.58-0.82c0.15-0.31,0.22-0.65,0.22-1.01c0-0.37-0.07-0.72-0.22-1.04
			c-0.15-0.33-0.34-0.61-0.58-0.84c-0.24-0.24-0.52-0.42-0.85-0.56c-0.33-0.14-0.67-0.21-1.04-0.21c-0.36,0-0.7,0.07-1.02,0.21
			c-0.32,0.14-0.6,0.33-0.83,0.56c-0.24,0.24-0.42,0.52-0.56,0.84c-0.14,0.33-0.21,0.67-0.21,1.04c0,0.36,0.07,0.7,0.21,1.01
			C31.27,78.75,31.46,79.02,31.69,79.26z'
        />
        <path
          fill='#FFFFFF'
          d='M99.89,0.07H0v99.89h49.74c0.37,0.03,0.74,0.05,1.13,0.05c0.42,0,0.82-0.02,1.21-0.05h47.81V0.07z
			 M4.07,83.02c-0.02-0.35-0.05-0.72-0.09-1.09l7.46,13.86c0.17,0.31,0.39,0.56,0.66,0.73c0.27,0.17,0.58,0.26,0.94,0.26h0.62
			c0.36,0,0.67-0.09,0.94-0.26c0.27-0.17,0.49-0.42,0.66-0.73l7.36-13.8c-0.03,0.36-0.06,0.71-0.08,1.06
			c-0.02,0.35-0.03,0.67-0.03,0.96v14.66H4.1V84.01C4.1,83.7,4.09,83.38,4.07,83.02z M98.6,98.67H56.76
			c1.14-0.62,2.12-1.44,2.94-2.46l-1.78-1.94c-0.18-0.19-0.39-0.29-0.64-0.29c-0.15,0-0.28,0.03-0.4,0.09
			c-0.12,0.06-0.24,0.14-0.35,0.24c-0.37,0.34-0.74,0.62-1.12,0.85c-0.38,0.23-0.77,0.42-1.2,0.56c-0.42,0.15-0.88,0.25-1.38,0.32
			c-0.5,0.07-1.06,0.1-1.68,0.1c-1.03,0-2-0.19-2.9-0.58c-0.9-0.39-1.68-0.95-2.34-1.68c-0.66-0.73-1.19-1.63-1.57-2.69
			c-0.39-1.07-0.58-2.28-0.58-3.65c0-1.32,0.19-2.52,0.58-3.58c0.39-1.06,0.93-1.96,1.62-2.7c0.69-0.74,1.52-1.31,2.47-1.71
			c0.96-0.4,2.01-0.6,3.16-0.6c0.98,0,1.78,0.1,2.42,0.3c0.63,0.2,1.16,0.42,1.57,0.66c0.42,0.24,0.76,0.45,1.03,0.66
			c0.27,0.2,0.53,0.3,0.79,0.3c0.25,0,0.44-0.05,0.58-0.15c0.14-0.1,0.26-0.22,0.35-0.35l1.52-2.1c-0.97-0.95-2.12-1.72-3.48-2.3
			c-1.35-0.58-2.93-0.87-4.74-0.87c-1.85,0-3.54,0.31-5.08,0.92c-1.53,0.61-2.85,1.47-3.95,2.57c-1.1,1.1-1.95,2.41-2.56,3.93
			c-0.61,1.52-0.91,3.2-0.91,5.03c0,1.81,0.27,3.47,0.82,5c0.54,1.53,1.33,2.84,2.35,3.94c0.82,0.88,1.77,1.6,2.85,2.17h-9.54V82.46
			h-4.16v16.21h-4.95V75.39h-3.42c-0.2,0-0.38,0.01-0.52,0.02c-0.15,0.01-0.28,0.04-0.4,0.08c-0.12,0.04-0.22,0.12-0.32,0.22
			c-0.1,0.1-0.19,0.24-0.28,0.4L14.37,89.6c-0.19,0.36-0.37,0.74-0.54,1.13c-0.17,0.39-0.33,0.79-0.49,1.2
			c-0.16-0.39-0.32-0.78-0.48-1.17c-0.16-0.39-0.34-0.76-0.53-1.12L5.06,76.11c-0.09-0.17-0.19-0.3-0.29-0.4
			c-0.1-0.1-0.21-0.17-0.33-0.22c-0.12-0.04-0.25-0.07-0.4-0.08c-0.15-0.01-0.32-0.02-0.52-0.02H1.29V1.35H98.6V98.67z'
        />
      </g>
    </g>
  </svg>
)

export default MiCFooterLogo
