import { CompleteHeader, Footer } from '@/molecules'

import { motion } from 'framer-motion'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { createContext, useContext, useEffect, useState } from 'react'

export const H1Context = createContext(false)
export const useH1Status = () => useContext(H1Context)

const motionWrapperStyle = {
  // height: '100%',
  // display: 'flex',
  // alignContent: 'center',
  // flexDirection: 'column',
  // justifyContent: 'flex-start',
  // overflowX: 'hidden',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',
  height: '100dvh',
  overflowX: 'hidden',
  maxWidth: '100dvw',
}

const RootLayout = ({ header, footer, children, title }) => {
  const router = useRouter()
  const isPreview = router.isPreview || false
  const pageTitle = title.replaceAll('-', ' ')
  NProgress.configure({ showSpinner: false })
  const [hasH1InPage, setHasH1InPage] = useState(false)

  useEffect(() => {
    const handleStart = () => NProgress.start()
    const handleComplete = () => NProgress.done()

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  // Verifica dell'esistenza di un h1 nella pagina dopo il rendering
  useEffect(() => {
    const checkForH1 = () => {
      const h1Elements = document.querySelectorAll('h1')
      setHasH1InPage(h1Elements.length > 0)
    }

    // Timeout per assicurarsi che il DOM sia completamente renderizzato
    const timeoutId = setTimeout(checkForH1, 0)

    return () => clearTimeout(timeoutId)
  }, [router.asPath]) // Riesegui quando cambia il percorso

  // Modifica dei children per aggiungere isFirstSection=true alla prima sezione
  const enhancedChildren = React.Children.map(children, (child) => {
    if (!child || !child.props || !child.props.sections) {
      return child
    }

    return React.cloneElement(child, {
      ...child.props,
      sections: child.props.sections.map((section, index) => {
        if (index === 0) {
          return { ...section, isFirstSection: true }
        }
        return section
      }),
    })
  })

  return (
    <motion.div
      style={motionWrapperStyle}
      key={pageTitle || 'root-layout'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        stiffness: 60,
        damping: 20,
        duration: 0.5,
      }}
    >
      {/* {isPreview ? <PreviewBadge /> : null} */}
      <Head>
        <title>{`${pageTitle} - Digital Library`}</title>
        <meta
          name='description'
          content={`
            Pagina ${title} del sito dell'Istituto Centrale per la Digitalizzazione del Patrimonio Culturale
          `}
        />
      </Head>

      <div className='skip-links'>
        <Link
          aria-label={`Vai al contenuto principale`}
          href='#header'
          className='skip-link'
          tabIndex={0}
        >
          Naviga all'header
        </Link>
        <Link
          href='#main'
          aria-label={`Vai al contenuto principale`}
          className='skip-link'
          tabIndex={0}
        >
          Naviga al contenuto
        </Link>
        <Link
          aria-label={`Vai al footer`}
          href='#footer'
          className='skip-link'
          tabIndex={0}
        >
          Naviga al footer
        </Link>
      </div>

      <CompleteHeader header={header} />
      <H1Context.Provider value={hasH1InPage}>
        <main
          id='main'
          className='main'
        >
          {enhancedChildren}
        </main>
      </H1Context.Provider>
      <Footer footer={footer} />
    </motion.div>
  )
}

export default RootLayout
